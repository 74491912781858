export const appTheme = {
    dark : {
        pageBackground : "#171E2E",
        background : "#1F2937",
        primary : "#556FAA",
        backgroundAccent : "#ABE1ED",
        secondary : "#2B3755",
        secondary1 : "#2A3755",
        secondary2 : "#1F2937",
        accent : "#23D3ED",
        accentDark : "#287593",
        text : "#fff",
        textBackground : "#fff",
        // textSecondary : "#5C7AA3",
        textSecondary : "#888f96",
        textDark : "black",
        textLight1 : "#bbc4dd",
        borderLines: "#334366",
        navBarLinks: "#CCD4E6",
        customButtonBorder:"#2E3D52",
        success : "green",
        successLight : "#dbf0e3",
        verified : "#4bca81",
        black : "#000",
        homeBorder : "blue",
        tertiaryColor:"#253141",
        tertiaryTextColor:"#5C7AA3",
        checkBoxContainer:"#445988",
        checkBoxContainerColor:"#BBC6DD",
        selectedCheckBoxColor:"#DDDCEF",
        selectedCheckBox:"#373372",
        dashboardBorderLine:"#222C44",
        dashboardNavbar:" #27324E",
        transparent: "transparent",
        danger : "#ea465a",
    },
    light : {
        pageBackground : "#E4E4E4",
        background : "#fff",
        secondaryDark : "#2B3755", //"#1F2937",
        backgroundDark : "#171717",
        backgroundLight : "#F3F5F7",
        primary : "#556FAA",
        backgroundAccent : "#ABE1ED",
        secondary : "#ABE1ED",
        secondary2 : "#1F2937", 
        accent : "#00b2c5", // "#23D3ED",
        textBackground : "#fff",
        textLight : "#fff",
        textLight1 : "#bbc4dd",
        text : "#171717",
        textSecondary : "#888f96",
        borderLines: "#CBD2D9",
        dashboardBorderLine: "#CBD2D9",
        navBarLinks: "#52606D",
        customButtonBorder:"#CBD2D9",
        homeBorder : "#bbb7b7",
        success : "green",
        successLight : "#dbf0e3",
        verified : "#4bca81",
        black : "#000",
        tertiaryColor:"#F9FAFB",
        tertiaryTextColor:"#52606D",
        checkBoxContainer:"#445988",
        checkBoxContainerColor:"#BBC6DD",
        selectedCheckBoxColor:"#DDDCEF",
        selectedCheckBox:"#373372",
        dashboardBorderLine:"#BBC4DD",
        buttonBorders: "#23D3ED",
        transparent: "transparent",
        danger : "#ea465a",
        lightBoxShadow: "rgba(14, 31, 53, 0.08)"
    }
}