import {
    Button,
    Divider,
    Grid,
    makeStyles,
    withWidth,
} from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import {
    breadCrumbActions,
    breadCrumbStrings,
    CustomDialog,
} from "../../../../includes";
import CustomInputFields from "../../../../includes/CustomInputFields/CustomInputFields";
import { backButtonActions } from "../../../../includes/CustomBackNav/actions";
import { backButtonStrings } from "../../../../includes/CustomBackNav/config";
import {
    giftDappRouteNames,
    ROUTE_DISPLAY_STRINGS,
    redditInputValidator,
    twitterInputValidator,
    youtubeInputValidator,
    discordInputValidator,
    discordInviteInputValidator,
    telegramInputValidator,
    extractTelegram_group_channel_Name,
    getTelegram_group_channel_Member,
    getTelegramBotId,
    getTelegramBotUsername,
} from "../../../../utils";
import { giftRewardActions } from "../../actions";
import { giftRewardStrings, GIFT_REWARD_REDUCER_NAME } from "../../config";
import GiftDappRewardSetupFooterButtons from "../GiftDappRewardSetupFooterButtons/GiftDappRewardSetupFooterButtons";
import GiftDappRewardSetupHeader from "../GiftDappRewardSetupHeader/GiftDappRewardSetupHeader";
import GiftDappRewardSetupInput from "../GiftDappRewardSetupInput/GiftDappRewardSetupInput";
import { useDispatch, useSelector } from "react-redux";
import { errorToast, successToast } from "../../../../includes";
import GiftDappRewardTelegramLink from "./GiftDappRewardTelegramLink/GiftDappRewardTelegramLink";
// import {
//     validateChennelOwner,
//     getTelegram_group_channel_Member,
// } from "../../../../../../includes/TelegramBot";

const style = makeStyles(({ colors, isDark, breakpoints }) => ({
    root: {},
    divider: {
        background: colors.borderLines,
    },
    telegram: {},
    lable: {},

    popup: {
        backgroundColor: colors.background,
        padding: "3rem 2rem",
        color: colors.text,
    },
    modal_header: {
        marginBottom: "2.5rem",
    },
    modal_sub_header: {
        color: colors.accent,
        opacity: 0.6,
    },
    popup_text: {
        color: colors.text,
        opacity: 0.7,
        marginBottom: "1rem",
    },
    popup_header: {
        fontSize: "1.6rem",
        fontWeight: 900,
    },
    save_btn: {
        display: "block",
        marginLeft: "auto",
        marginTop: "1.5rem",
    },
    verify_btn: {
        display: "block",
        marginTop: "2rem",
        width: "100%",
        backgroundColor: colors.accent,
        color: colors.background,
        fontWeight: 900,
        "&:hover": {
            backgroundColor: colors.accent,
        },
    },
    code: {
        fontFamily: "courier",
        letterSpacing: 2,
        color: colors.accent,
        margin: "0 2",
    },
}));
export const REWARD_SOCIAL_LINK_FIELD_NAMES = {
    LINKS: "links",
    FACEBOOK_URL: "facebookUrl",
    TWITTER_URL: "twitterUrl",
    YOUTUBE_URL: "youtubeUrl",
    TELEGRAM_URL: "telegramUrl",
    DISCORD_URL: "discordUrl",
    DISCORD_INVITE_URL: "discordInviteUrl",
    REDDIT_URL: "redditUrl",
};
const formConfig = [
    {
        name: REWARD_SOCIAL_LINK_FIELD_NAMES.LINKS,
        label: "Links",
        type: "switch",
    },
    {
        name: REWARD_SOCIAL_LINK_FIELD_NAMES.TWITTER_URL,
        label: "Twitter Account URL",
        prefix: "Username",
        instruction: "To verify 2 twitter links, separate with comma(,)"
    },
    {
        name: REWARD_SOCIAL_LINK_FIELD_NAMES.YOUTUBE_URL,
        label: "Youtube Channel URL/ID",
        prefix: "Channel link/ID",
    },
    {
        name: REWARD_SOCIAL_LINK_FIELD_NAMES.REDDIT_URL,
        label: "Reddit user/subreddit URL",
        prefix: "Reddit link",
    },
    {
        name: REWARD_SOCIAL_LINK_FIELD_NAMES.DISCORD_URL,
        label: "Discord server/channel URL",
        prefix: "Discord link",
    },
    {
        name: REWARD_SOCIAL_LINK_FIELD_NAMES.TELEGRAM_URL,
        label: "Telegram public group Name",
        prefix: "Telegram",
    },
    // {name : REWARD_SOCIAL_LINK_FIELD_NAMES.FACEBOOK_URL, label : "Facebook Page URL/ID", prefix : "Username"},
];
function _GiftDappRewardSetupSocialLinks({
    width,
    onContinue,
    profileBreadCrumbAction,
    detailBreadCrumbAction,
}) {
    const classes = style(),
        [telegramInput, setTelegramInput] = useState(),
        [openDiscordServerUrlPopup, setOpenDiscordServerUrlPopup] =
            useState(false),
        [openTelegramBotActionPopup, setOpenTelegramBotActionPopup] =
            useState(false),
        [verifyingTelegram, setVerifyingTelegram] = useState(false),
        dispatch = useDispatch(),
        giftRewardReducer = useSelector(
            (state) => state[GIFT_REWARD_REDUCER_NAME]
        ),
        {
            [giftRewardStrings.rewardData]: rewardData,
            [giftRewardStrings.isEdit]: isEdit,
        } = giftRewardReducer,
        { [REWARD_SOCIAL_LINK_FIELD_NAMES.LINKS]: links } = rewardData || {},
        onChange = useCallback(
            (name) =>
                ({ target: { value, checked } }) => {
                    const { LINKS } = REWARD_SOCIAL_LINK_FIELD_NAMES;
                    giftRewardActions.payload({
                        [giftRewardStrings.rewardData]: {
                            ...rewardData,
                            [name]: name === LINKS ? checked : value,
                        },
                    })(dispatch);
                },
            [JSON.stringify(rewardData)]
        ),
        validateOnBlur =
            (name) =>
            ({ target: { value } }) => {
                if (name === REWARD_SOCIAL_LINK_FIELD_NAMES.LINKS) return;
                else if (value.length == 0) return; //the user did not enter anything
                else if(name === REWARD_SOCIAL_LINK_FIELD_NAMES.TWITTER_URL){
                    const urls = String(value).split(",");
                    if(value && urls.length > 2)
                        return errorToast(
                            "Twitter urls can not be more than 2"
                        )(dispatch);
                    for (let j = 0; j < urls.length; j++) {
                        const twitterLink = String(urls[j])?.trim();
                        if(!inputValidation(name, twitterLink)){
                            const errMsg = getSocialInputErrorMsg(name);
                            errorToast(errMsg)(dispatch);
                            return;
                        }
                    }
                }
                else if (!inputValidation(name, value)) {
                    const errMsg = getSocialInputErrorMsg(name);
                    setTimeout(() => {
                        errorToast(errMsg)(dispatch);
                    }, 300);

                    if (
                        name ===
                        REWARD_SOCIAL_LINK_FIELD_NAMES.DISCORD_INVITE_URL
                    )
                        setOpenDiscordServerUrlPopup(true);

                    return;
                }

                if (name === REWARD_SOCIAL_LINK_FIELD_NAMES.DISCORD_URL)
                    setOpenDiscordServerUrlPopup(true);
                if (name === REWARD_SOCIAL_LINK_FIELD_NAMES.TELEGRAM_URL)
                    setOpenTelegramBotActionPopup(true);
            },
        onCloseTelegramBotModal = () => {
            //when user close the modal, we assume they are not interensted in adding the bot to the group and so we need to clear the telegram group name colletcted
            giftRewardActions.payload({
                [giftRewardStrings.rewardData]: {
                    ...rewardData,
                    [REWARD_SOCIAL_LINK_FIELD_NAMES.TELEGRAM_URL]: "",
                },
            })(dispatch);

            setOpenTelegramBotActionPopup(false);
        },
        inputValidation = (name, value) => {
            switch (name) {
                case REWARD_SOCIAL_LINK_FIELD_NAMES.TWITTER_URL:
                    return twitterInputValidator(value);
                case REWARD_SOCIAL_LINK_FIELD_NAMES.YOUTUBE_URL:
                    return youtubeInputValidator(value);
                case REWARD_SOCIAL_LINK_FIELD_NAMES.REDDIT_URL:
                    return redditInputValidator(value);
                case REWARD_SOCIAL_LINK_FIELD_NAMES.DISCORD_URL:
                    return discordInputValidator(value);
                case REWARD_SOCIAL_LINK_FIELD_NAMES.DISCORD_INVITE_URL:
                    return discordInviteInputValidator(value);
                case REWARD_SOCIAL_LINK_FIELD_NAMES.TELEGRAM_URL:
                    return telegramInputValidator(value);
                default:
                    break;
            }
        },
        getSocialInputErrorMsg = (name) => {
            switch (name) {
                case REWARD_SOCIAL_LINK_FIELD_NAMES.TWITTER_URL:
                    return "Invalid! enter valid twitter handle or link";
                case REWARD_SOCIAL_LINK_FIELD_NAMES.YOUTUBE_URL:
                    return <div style={{display : "flex", flexDirection : "column"}}>
                        <p>Invalid! enter valid Youtube channel link or channel ID</p>
                        <a href="https://www.youtube.com/account_advanced" target="_blank">
                            Get your channel ID here
                        </a>
                    </div>
                case REWARD_SOCIAL_LINK_FIELD_NAMES.REDDIT_URL:
                    return "Invalid! enter valid reddit account or subreddit link";
                case REWARD_SOCIAL_LINK_FIELD_NAMES.DISCORD_URL:
                    return "Invalid! enter valid discord server or channel link";
                case REWARD_SOCIAL_LINK_FIELD_NAMES.DISCORD_INVITE_URL:
                    return "Invalid! enter valid discord server invite link";
                case REWARD_SOCIAL_LINK_FIELD_NAMES.TELEGRAM_URL:
                    return "Invalid! enter valid public telegram group name or link";
                default:
                    break;
            }
        },
        onReset = useCallback(() => {
            giftRewardActions.payload({
                [giftRewardStrings.rewardData]: {
                    [REWARD_SOCIAL_LINK_FIELD_NAMES.LINKS]: "",
                    [REWARD_SOCIAL_LINK_FIELD_NAMES.TWITTER_URL]: "",
                    [REWARD_SOCIAL_LINK_FIELD_NAMES.YOUTUBE_URL]: "",
                    [REWARD_SOCIAL_LINK_FIELD_NAMES.REDDIT_URL]: "",
                    [REWARD_SOCIAL_LINK_FIELD_NAMES.DISCORD_URL]: "",
                    [REWARD_SOCIAL_LINK_FIELD_NAMES.FACEBOOK_URL]: null,
                },
            })(dispatch);
        }, []);
    useEffect(
        () => {
            const { PROFILE, DETAILS, SOCIAL } =
                ROUTE_DISPLAY_STRINGS.SETUP_STRINGS;
            breadCrumbActions.payload({
                [breadCrumbStrings.visible]: true,
                [breadCrumbStrings.crumbs]: [
                    {
                        name: isEdit
                            ? ROUTE_DISPLAY_STRINGS.DASHBOARD
                            : ROUTE_DISPLAY_STRINGS.REWARD,
                        path: isEdit
                            ? giftDappRouteNames().dashboard
                            : giftDappRouteNames().reward,
                    },
                    { name: PROFILE, action: profileBreadCrumbAction },
                    { name: DETAILS, action: detailBreadCrumbAction },
                    { name: SOCIAL },
                ],
            })(dispatch);
            backButtonActions.payload({
                [backButtonStrings.visible]: true,
                [backButtonStrings.text]: "Back to project detail page",
                [backButtonStrings.action]: detailBreadCrumbAction,
            })(dispatch);
        },
        [
            /* JSON.stringify(breadCrumbReducer) */
        ]
    );

    const verifyBotExistenceInGroup = async () => {
        const Telegram_group_channel_Name = extractTelegram_group_channel_Name(
            rewardData[REWARD_SOCIAL_LINK_FIELD_NAMES.TELEGRAM_URL]
        );
        setVerifyingTelegram(true);
        const res = await getTelegram_group_channel_Member(
            Telegram_group_channel_Name,
            getTelegramBotId()
        );
        setVerifyingTelegram(false);
        if (res.ok) {
            if (["creator", "administrator", "member"].includes(res.status)) {
                successToast("bot added successfully!")(dispatch);
                setOpenTelegramBotActionPopup(false);
            } else {
                errorToast(
                    "You have not added the bot to the group/channel yet, please add the bot and try again"
                )(dispatch);
            }
        } else {
            errorToast(res.description)(dispatch);
            onCloseTelegramBotModal();
        }
    };

    const onSaveAdditionalInfo = () => {
        setOpenDiscordServerUrlPopup(false);
        // had to simulate the onBlur
        validateOnBlur(REWARD_SOCIAL_LINK_FIELD_NAMES.DISCORD_INVITE_URL)({
            target: {
                value: rewardData?.[
                    REWARD_SOCIAL_LINK_FIELD_NAMES.DISCORD_INVITE_URL
                ],
            },
        });
    },
    validateBeforeContinue = useCallback(() => {
        const { LINKS, TWITTER_URL } = REWARD_SOCIAL_LINK_FIELD_NAMES;
        if(rewardData?.[LINKS]) for (let i = 0; i < formConfig.length; i++) {
            const { name } = formConfig[i],
            value = rewardData?.[name];
            if(!value || name === LINKS) continue;
            else if(name === TWITTER_URL){
                const urls = String(value).split(",");
                if(value && urls.length > 2)
                    return errorToast(
                        "Twitter urls can not be more than 2"
                    )(dispatch);
                for (let j = 0; j < urls.length; j++) {
                    const twitterLink = urls[j].trim();
                    if(!inputValidation(name, twitterLink)){
                        const errMsg = getSocialInputErrorMsg(name);
                        errorToast(errMsg)(dispatch);
                        return;
                    }
                }
            }
            else if (!inputValidation(name, value)){
                const errMsg = getSocialInputErrorMsg(name);
                errorToast(errMsg)(dispatch);
                return;
            }
        }
        onContinue && onContinue();
    }, [JSON.stringify(rewardData)]);

    return (
        <div className={classes.root}>
            <GiftDappRewardSetupHeader
                header="Social links"
                subheader="List your social media links, and verify game players follow you"
            />
            {formConfig.map((field, index) => {
                const { name } = field;
                const otherValues = {};
                if (name === REWARD_SOCIAL_LINK_FIELD_NAMES.LINKS) {
                    otherValues.checked = links;
                }
                return (
                    <div key={index}>
                        {width !== "xs" && (
                            <Divider className={classes.divider} />
                        )}
                        <GiftDappRewardSetupInput
                            key={index}
                            {...field}
                            disabled={
                                name !== REWARD_SOCIAL_LINK_FIELD_NAMES.LINKS &&
                                !links
                            }
                            onChange={onChange(name)}
                            onBlur={validateOnBlur(name)}
                            value={rewardData?.[name]}
                            {...otherValues}
                        />
                    </div>
                );
            })}

            <GiftDappRewardSetupFooterButtons
                onContinue={validateBeforeContinue}
                onReset={onReset}
            />

            <CustomDialog
                open={openDiscordServerUrlPopup}
                within={true}
                close={() => {
                    setOpenDiscordServerUrlPopup(false);
                }}
            >
                <div className={classes.popup}>
                    <h3 className={classes.popup_header}>
                        Additional infomation
                    </h3>
                    <p className={classes.popup_text}>
                        Please input the discord server invite link to allow
                        reward claimer join incase they are not already a member
                    </p>
                    <div className={classes.input_container}>
                        <CustomInputFields
                            field={{
                                name: "discord invite link",
                                type: "link",
                                label: "discord invite",
                                onChange: onChange(
                                    REWARD_SOCIAL_LINK_FIELD_NAMES.DISCORD_INVITE_URL
                                ),
                                value: rewardData?.[
                                    REWARD_SOCIAL_LINK_FIELD_NAMES
                                        .DISCORD_INVITE_URL
                                ],
                            }}
                        />
                        <Button
                            variant="contained"
                            disabled={false}
                            className={classes.save_btn}
                            onClick={onSaveAdditionalInfo}
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </CustomDialog>

            <CustomDialog
                open={openTelegramBotActionPopup}
                within={true}
                close={onCloseTelegramBotModal}
            >
                <div className={classes.popup}>
                    <div className={classes.modal_header}>
                        <h3 className={classes.popup_header}>One more step</h3>
                        <span className={classes.modal_sub_header}>
                            Add Bot to group
                        </span>
                    </div>
                    <p className={classes.popup_text}>
                        In order to allow us to verify your group members, we
                        need you to add{" "}
                        <code
                            className={classes.code}
                        >{`@${getTelegramBotUsername()}`}</code>{" "}
                        to the group.
                    </p>
                    <p className={classes.popup_text}>
                        After adding the bot to the group, come back and click
                        the verify button below so we can verify the presence of
                        the bot in the group
                    </p>
                    <div className={classes.input_container}>
                        <p className={classes.popup_text}>
                            Bot username:{" "}
                            <code
                                className={classes.code}
                            >{`@${getTelegramBotUsername()}`}</code>
                        </p>
                        <Button
                            variant="contained"
                            disabled={verifyingTelegram}
                            className={classes.verify_btn}
                            onClick={verifyBotExistenceInGroup}
                        >
                            {verifyingTelegram ? "verifying..." : "Verify"}
                        </Button>
                    </div>
                </div>
            </CustomDialog>
        </div>
    );
}
export const GiftDappRewardSetupSocialLinks = withWidth()(
    _GiftDappRewardSetupSocialLinks
);
export { GiftDappRewardSetupSocialLinks as default };
