import { makeStyles } from '@material-ui/core'
import { faFacebook, faLinkedin, faTelegram, faTwitter, faGithub, faDiscord, faMedium } from '@fortawesome/free-brands-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Link} from 'react-router-dom'
import { landingRouteNames } from '../../..';
import React from 'react'
const style = makeStyles(({colors, breakpoints}) => ({
    root: {
        padding: "5rem",
        paddingBottom: "1.5rem",
        backgroundColor: "#000C15",
        color: "#fff",
        [breakpoints.down('sm')]: {
            padding: "1rem",
        }
    },
    footerGrid: {
        display: "flex",
        justifyContent: "space-between",
        fontSize: "1.8rem",
        [breakpoints.down('xl')]: {
            fontSize: "1rem",
        },
        [breakpoints.down('sm')]: {
            flexWrap: "wrap",
        }
    },
    section: {
        width: "20%",
        [breakpoints.down('sm')]: {
            width: "50%",
        }
    },
    sectionTitle: {
        fontFamily:" DM Sans",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#566676" ,
        [breakpoints.down('xl')]: {
            fontSize: "16px",
        },
    },
    inputContainer: {
        position: "relative",
        display: "flex",
        width: "100%",
        height: "3rem",
        backgroundImage: "linear-gradient(to right, #ff00cc 50%, #343399 100%)",
        padding: ".1rem",
        borderRadius: ".4rem",
        marginBottom: "2.5rem",
        [breakpoints.down('sm')]: {
            height: "2.5rem",
        },
    },
    emailInput: {
        backgroundColor: "#0b0b30",
        display: "inline-block",
        width: "100%",
        height: "100%",
        borderRadius: ".5rem",
        boxSizing: "border-box",
        padding: "0 .7rem",
        fontSize: "1.5rem",
        outline: "none",
        color: "#fff",
        "&:placeholder": {
            color: "#fff",
            opacity: "0.5",
        },
        [breakpoints.down('xl')]: {
            fontSize: "1rem",
        },
        [breakpoints.down('sm')]: {
            fontSize: ".7rem",
        },
    },
    submitBtn: {
        position: "absolute",
        width: "20%",
        height: "100%",
        border: "none",
        outline: "none",
        top: 0,
        right: 0,
        borderRadius: ".4rem",
        boxSizing: "border-box",
        cursor: "pointer",
        backgroundImage: "linear-gradient(to right, #ff00cc 10%, #343399 100%)",
        [breakpoints.down('sm')]: {
            width: "25%",
        }
    },
    arrowIcon: {
        color: "#fff",
    },
    socials: {
        width: "100%",
    },
    socialsHeading: {
        width: "100%",
         marginTop:"0px",
         marginBottom: "0PX",
    },
    socialIconsWrapper: {
        width: "60%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-end",
        [breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    socialIcons: {
        color: "#fff",
        transition: "all ease-in-out .3s",
        "&:hover": {
            opacity: .8,
        },
    },
    sectionItem: {
        fontFamily: "DM Sans",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "16px",
        lineHeight: "24px",
        color: "#FFFFFF",
        display: "block",
        margin: "1.5rem 0",
        textDecoration: "none",
        transition: "all ease-in-out",
        "&:hover": {
            opacity: .8,
        }
    },
    footerBottom: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "4rem",
        fontSize: "1.8rem",
        [breakpoints.down('xl')]: {
            fontSize: "1rem",
        },
        [breakpoints.down('sm')]: {
            flexWrap: "wrap",
        }
    },
    copyright: {
        width: "25%",
        marginBottom: 0,
        [breakpoints.down('sm')]: {
            width: "100%",
            display: "block",
            marginBottom: "1.5rem"
        }
    },
    bottomRight: {
        display: "inline-block",
    },
    bottomRightItem: {
        margin: "0 2rem",
        color: "#fff",
        textDecoration: "none",
        transition: "all ease-in-out",
        "&:hover": {
            opacity: .8,
        },
        "&:last-of-type": {
            marginRight: 0.
        },
        [breakpoints.down('sm')]: {
            marginLeft: 0,
        },
        [breakpoints.down(482)]: {
            width: "50%",
            marginBottom: ".8rem",
            display: "inline-block",
            margin: ".5rem 0",
        }
    },
    logoImage: {
        // width: "23.38px",
        // height: "22.67px",
      },
      logoContainer: {
        display: "flex",
        alignItems: "flex-start"
        
      },

      featuredContainer :{
        display: "flex",
        justifyContent: "space-between",
        [breakpoints.down(900)]: {
            flexDirection: "column-reverse",
        },
        [breakpoints.down("sm")]: {
            padding: "1rem",
        }
    },
    leftSection: {
        width: "30%",
        [breakpoints.down("md")]: {
            width: "90%",
        }
    },
    rightSection: {
        width: "70%",
        marginTop: "0rem",
        [breakpoints.down("md")]: {
            width: "90%",
        },
        [breakpoints.down("sm")]: {
            width: "90%",
        },
        [breakpoints.down("xs")]: {
            width: "100%",
        }

    },

    leftSectionText:{
        fontFamily: "DM Sans",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "16px",
        lineHeight: "24px",
        marginBottom: "15px",
        marginTop: "15px",
        width: "70%",
        [breakpoints.down("sm")]: {
            width: "100%",
        },
    }
}))
export default function LandingFooter () {
    const classes = style();
    return (
        <footer className = {classes.root}>
        <div className = {classes.featuredContainer}>
            <div className = {classes.leftSection}>
                {/* <div className = {classes.section}> */}
                        <div className = {classes.socials}>
                            <div className = {classes.logoContainer}>
                            <img
                            src="/landlogo.png"
                            className={classes.logoImage}
                            alt="LaunchPad Rigel Protocol"
                        />
                            {/* <h3 className = {classes.socialsHeading}>
                                Rigel Protocol
                            </h3> */}
                            </div>
                            <p className = {classes.leftSectionText}>DApps with the best experience and low fees.</p>
                            <div className = {classes.socialIconsWrapper}>
                                <a href = "https://www.linkedin.com/company/rigelprotocol" target='_blank' className = {classes.socialIcons}>
                                    <FontAwesomeIcon icon = {faLinkedin} />
                                </a>
                                <a href = "https://www.t.me/rigelprotocol" target='_blank' className = {classes.socialIcons}>
                                    <FontAwesomeIcon icon = {faTelegram} />
                                </a>
                                <a href = "https://discord.gg/j86NH95GDD" target='_blank' className = {classes.socialIcons}>
                                    <FontAwesomeIcon icon = {faDiscord} />
                                </a>
                                <a href={landingRouteNames().home} target='_blank' className = {classes.socialIcons}>
                                    <FontAwesomeIcon icon = {faFacebook} />
                                </a>
                                <a href = "https://twitter.com/rigelprotocol" target='_blank' className = {classes.socialIcons}>
                                    <FontAwesomeIcon icon = {faTwitter} />
                                </a>
                                <a href = "https://medium.com/rigelprotocol" target='_blank' className = {classes.socialIcons}>
                                    <FontAwesomeIcon icon = {faMedium} />
                                </a>
                                <a href = "https://github.com/rigelprotocol" target='_blank' className = {classes.socialIcons}>
                                    <FontAwesomeIcon icon = {faGithub} />
                                </a>
                            </div>
                        </div>
                    {/* </div> */}
            
            </div>
            <div className = {classes.rightSection}>
            <div className = {classes.footerGrid}>
            <div className = {classes.section}>
                <h3 className = {classes.sectionTitle}>Products</h3>
                <a href  = "https://smartswap.rigelprotocol.com/" target='_blank'  className = {classes.sectionItem}>SmartSwap</a>
                <a href  = {landingRouteNames().app}  className = {classes.sectionItem}>Gift DApp</a>
                 <a href="https://smartswap.rigelprotocol.com/autotrade?chain=bsc"target='_blank' className = {classes.sectionItem}>Autotrade
                </a>
               <a target='_blank' className = {classes.sectionItem} href="https://smartswap.rigelprotocol.com/smartbid?chain=bsc">SmartBid</a>
              <a href="https://launchpad.rigelprotocol.com/" target='_blank' className = {classes.sectionItem}>LaunchPad</a>
              <a href="https://p2p.rigelprotocol.com/" target='_blank' className = {classes.sectionItem}>P2P</a>
            </div>
            <div className = {classes.section}>
            <h3 className = {classes.sectionTitle}>Company</h3>
                <a href='https://rigelprotocol.com/about.html' target='_blank' className = {classes.sectionItem}>About Us</a>
                <a href='https://rigelprotocol.com/#roadMaps' target='_blank' className = {classes.sectionItem}>RoadMap</a>
                <a href='https://rigelprotocol.com/#partners' target='_blank' className = {classes.sectionItem}>Partners</a>
                <a href='https://rigelprotocol.com/press.html' target='_blank' className = {classes.sectionItem}>Press Resources</a>
            </div>
            <div className = {classes.section}>
            <h3 className = {classes.sectionTitle}>Support</h3>
                <a href='https://rigelprotocol.com/faqs.html' target = '_blank' className = {classes.sectionItem}>FAQs</a>
                <a href='https://medium.com/rigelprotocol' target = '_blank' className = {classes.sectionItem}>Blogs</a>

            </div>
            <div className = {classes.section}>
            <h3 className = {classes.sectionTitle}>Legal</h3>
                <a href='https://rigelprotocol.com/design-compliance.html' target='_blank' className = {classes.sectionItem}>Design Compliance</a>
                <a href='https://rigelprotocol.com/privacy-policy.html' target='_blank' className = {classes.sectionItem}>Privacy Policy</a>
                <a href='https://rigelprotocol.com/terms-and-condition.html' target='_blank' className = {classes.sectionItem}>Terms &amp; Conditions</a>
              <a href="https://rigelprotocol.com/AML"  className = {classes.sectionItem} target="_blank" >Anti-Money Laundering Policy</a> 
            </div>
            
        </div>
            </div>
        </div>
      
        {/* <div className = {classes.footerBottom}>
            <span className = {classes.copyright}>
                &copy; RigelProtocol {new Date().getFullYear().toString()}
            </span>
            <div className = {classes.bottomRight}>
                <Link to = "/" className = {classes.bottomRightItem}>
                    Design Compliance
                </Link>
                <Link to = "/" className = {classes.bottomRightItem}>
                    Privacy Policy
                </Link>
                <Link to = "/" className = {classes.bottomRightItem}>
                    Terms &amp; Conditions
                </Link>
            </div>
        </div> */}
    </footer>
    )
}