const YOUR_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY,
    YOUR_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
export const youtubeInit = function(){
    if(window.gapi) window.gapi.load("client:auth2", function() {
        window.gapi.auth2.init({client_id: YOUR_CLIENT_ID});
    });
}
function authenticate(callback) {
    return window.gapi.auth2.getAuthInstance()
        .signIn({scope: "https://www.googleapis.com/auth/youtube.readonly"})
}

async function loadClient() {
    if(window.gapi.client){ 
        window.gapi.client.setApiKey(YOUR_API_KEY);
        return window.gapi.client.load("https://www.googleapis.com/discovery/v1/apis/youtube/v3/rest");
    }
}

export function youtubeSearchChannel(){
    return window.gapi.client.youtube.channels.list({
        "part" : [
            "contentOwnerDetails"
        ], 
        "mine" : true,
        // "id": [extractYoutubeChannelId(id)]
    });
}
export {authenticate as youtubeAuth};
export {loadClient as youtubeLoadClient};
export async function getYoutubeChannelDetailsByID(channelID) {
    return window.gapi.client.youtube.channels.list({
      "part": ["snippet"],
      "id": [channelID]
    })
}




// Make sure the client is loaded and sign-in is complete before calling this method.
export async function execute(config = {}) {
    return window.gapi.client.youtube.subscriptions.list(config);
}
export async function executeSubscription(config = {}) {
    return window.gapi.client.youtube.subscriptions.list(config);
}
function executeChannels(config={}){
    return window.gapi.client.youtube.channels.list(config);
}

export const verifyYoutubeSubscription = async (channelID) => {
    // the user must have been authenticated before getting to the step where this function is called. so we can just load client and then execute
    await loadClient()
    return executeSubscription({
        "part": [
            "snippet", 
            "id",
            "contentDetails"
        ],
        "forChannelId": channelID,
        "mine": true
    });
}
export const youtubeGetMyChannels = async (channelID) => {
    // the user must have been authenticated before getting to the step where this function is called. so we can just load client and then execute
    await loadClient()
    return executeChannels({
        "part": [
            "snippet",
            "id", 
            "contentDetails"
        ],
        "mine" : true
    });
}
export const youtubeGetMyChannelByID = async (channelID) => {
    // the user must have been authenticated before getting to the step where this function is called. so we can just load client and then execute
    await loadClient()
    return executeChannels({
        "part": [
            "snippet",
            "id", 
            "contentDetails"
        ],
        "id" : channelID,
        "mine" : true
    });
}
