import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { appValues, contractLabels } from '../../helpers';
import { 
    createMetadata, getMetadata, 
    giftDappUtilActions, STORAGE_VARIABLE, 
    getAddressBook 
} from '../../utils';
import { giftDappUtilStrings, GIFT_DAPP_UTIL_REDUCER_NAME } from '../../utils/config';
import walletConnection from '../../utils/web3/walletConnection';
import { walletActions, walletStrings, WALLET_REDUCER_NAME } from '../structures';
import { CustomDialog, errorToast } from '../../includes';
import { LinearProgress, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
const style = makeStyles(({colors}) => ({
    dialogPaper : {
        background : colors.background,
        borderRadius : 20,
        border : "solid 1px " + colors.accentSecondary,
        color : colors.text,
        padding : 20,
    },
}));
const GiftDappSetup = React.memo(function(props) {
    const dispatch = useDispatch(),
    history = useHistory(),
    classes = style(),
    giftDappUtilReducer = useSelector(state=>state[GIFT_DAPP_UTIL_REDUCER_NAME]),
    {
        [giftDappUtilStrings.processing] : processing,
        [giftDappUtilStrings.processMsg] : processMsg,
    } = giftDappUtilReducer;
    useEffect(() => {
        giftDappUtilActions.payload({
            [giftDappUtilStrings.appDomain] : appValues.appDomain,
            [giftDappUtilStrings.appFrontend] : appValues.appFrontend,
            [giftDappUtilStrings.storageNetworkID] : STORAGE_VARIABLE.NETWORK_ID,
            [giftDappUtilStrings.storageNetworkType] : STORAGE_VARIABLE.NETWORK_TYPE,
            [giftDappUtilStrings.storageNetworkWallet] : STORAGE_VARIABLE.NETWORK_WALLET,
            [giftDappUtilStrings.storageLastUsedProfile] : STORAGE_VARIABLE.PROFILE_LASTUSED,
        })(dispatch);
        (async()=>{
            try{
                const contractAddresses = getAddressBook();
                walletActions({
                    [walletStrings.contractAddresses] : contractAddresses
                })(dispatch);
                const networkWallet = localStorage.getItem(STORAGE_VARIABLE.NETWORK_WALLET);
                //if there no networktype in the storage, don't login, allow use to choice network
                if(networkWallet){
                    walletConnection(contractAddresses, networkWallet, null)
                    // .then(() => successToast("Connected!")(dispatch))
                    // .catch(({message})=> errorToast(message)(dispatch));
                }
            }catch(error) {
                errorToast(error.message)(dispatch);
            }
            
        })();
    }, [])
    return <CustomDialog
        open={processing}
        classes={{
            dialogPaper : classes.dialogPaper,
        }}
    >
        <div className={classes.container}>
            <span>{processMsg || "Processing block transaction ...."}</span>
            <span>
                <LinearProgress/>
            </span>
        </div>
    </CustomDialog>;
})
export default GiftDappSetup;
export {GiftDappSetup};