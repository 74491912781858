import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import { GiftDappRewardHome } from './GiftDappRewardHome'
import { breadCrumbActions, breadCrumbStrings, CustomDialog, warningToast } from '../../includes'
import { giftRewardStrings, GIFT_REWARD_REDUCER_NAME, REWARD_FIELD_NAMES } from './config'
import { walletStrings, WALLET_REDUCER_NAME } from '../structures'
import { giftContractActions, giftDappRouteNames }from '../../utils';
import GiftDappRewardRequestFee from './GiftDappRewardRequestFee/GiftDappRewardRequestFee'
import { history } from '../../helpers'
import { giftRewardActions } from './actions'
import { backButtonActions } from '../../includes/CustomBackNav/actions'
import { backButtonStrings } from '../../includes/CustomBackNav/config';
import TelegramButton from '../GIftDappEvents/GiftDappEventClaims/SocialMediaVerify/TelegramButton'
const style = makeStyles(({colors, breakpoints}) => ({
    root : {
        marginBottom : 50,
        display : "flex",
        justifyContent : "center",
        
    },
    item : {
        display : "flex",
        justifyContent : "space-around",
        marginTop : 50,
    },
    type : {
        display : "flex",
        flexDirection : "column",
        alignItems : "center",
        marginTop : 50,
    },
    text : {
        fontSize : 12,
        display : "flex",
        flexDirection : "column",
        alignItems : "center",
        textAlign : "center",
    },
    btn : {
        marginTop : 30,
        color : "white"
    },
    summaryContainer : {
        color : colors.text
    },
    dialogPaper : {
        background : colors.background,
        border : "solid 1px " + colors.accentSecondary,
        overflow : "visible",
    },
    dialogClose : {
        background : colors.text,
        top : -40,
        padding : 5,
        "&:hover" : {
            background : colors.accent,
            color : colors.textSecondary
        }
    },
})),
DIALOG_TYPES = {
    REQUEST_FEE : "request_fee",
    CONFIRM : "confirm",
    URL : "share_url",
};
function GiftDappReward({location}) {
    /** TODO : CHECK IF THERE IS UNUSED PAID FEE */
    const classes = style(),
    dispatch = useDispatch(),
    pushedState = location.state,
    {[giftRewardStrings.rewardData] : editData} = pushedState || {},
    [state, _setState] = useState({
        openDialog : false,
        dialogType : null, //DIALOG_TYPES.URL,
        isProject : null,
    }),
    {
        isProject, openDialog, dialogType
    } = state,
    setState = (_state, callback) => _setState(state=>({...state, ..._state}), callback),
    address = useSelector(state=>state[WALLET_REDUCER_NAME][walletStrings.address]),
    giftRewardReducer = useSelector(state=>state[GIFT_REWARD_REDUCER_NAME]),
    {
        [giftRewardStrings.isEdit] : isEdit
    } = giftRewardReducer,
    ownerContinue = useCallback(isProject => {
        if(!address) return warningToast("Not connected to a network!")(dispatch);
        //this will replace every other data in the reward space.
        //else get the existing data into it
        giftRewardActions.resetRewardData({
            [giftRewardStrings.rewardTokens] : null,
            [giftRewardStrings.rewardData] : {
                [REWARD_FIELD_NAMES.IS_PROJECT] : isProject
            }
        })(dispatch);
        setState({
            isProject, 
            openDialog : true, 
            dialogType : DIALOG_TYPES.REQUEST_FEE
        });
    }, [address]),
    closeDialog = useCallback(() => {
        if(dialogType === DIALOG_TYPES.URL) 
            return history.push(giftDappRouteNames().dashboard);
        else setState({openDialog : false, dialogType : null})
    }, [dialogType]);
    useEffect(() => {
        backButtonActions.payload({
            [backButtonStrings.visible] : false
        })(dispatch);
        breadCrumbActions.payload({
            [breadCrumbStrings.visible] : false,
        })(dispatch);
    }, []);
    useEffect(() => {
        if(isEdit) {
            giftRewardActions.payload({
                [giftRewardStrings.isEdit] : false
            })(dispatch);
            giftRewardActions.resetRewardData()(dispatch)
        }
    }, [isEdit]);
    return (
        <div className={classes.root}>
            <GiftDappRewardHome
                rewardAction={ownerContinue}
            />
            <CustomDialog
                within
                open={openDialog}
                close={closeDialog}
                classes={{
                    dialogPaper : classes.dialogPaper,
                    _close : classes.dialogClose
                }}
            >
                {dialogType === DIALOG_TYPES.REQUEST_FEE && <GiftDappRewardRequestFee
                    onClose={closeDialog}
                    onContinue={() => {
                        giftContractActions(isProject).switchContract();
                        history.push(giftDappRouteNames().rewardSetup)
                    }}
                />}
            </CustomDialog>
        </div>
    )
}
GiftDappReward.propTypes = {
    
}
export default GiftDappReward;
export {GiftDappReward};