import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { AppBar, Hidden, IconButton, makeStyles, Toolbar, Tooltip } from "@material-ui/core";
import { Link } from "react-router-dom";
import {
    getRewards,
    giftDappUtilActions,
    GIFT_DAPP_LOCAL_STORAGE_VAR,
    giftDappRouteNames,
} from "../../../utils";
import { history } from "../../../helpers";
import { GiftDappConnection } from "./GiftDappConnection";
import { useDispatch, useSelector } from "react-redux";
import { walletStrings, WALLET_REDUCER_NAME } from "../GiftDappWallets";
import { useLazyQuery } from "@apollo/client";
import { headerStrings, HEADER_REDUCER_NAME } from "./config";
import { headerActions } from "./actions";
import {
    giftRewardStrings,
    GIFT_REWARD_REDUCER_NAME,
} from "../../GiftDappReward";
import { Menu } from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import { withWidth } from "@material-ui/core";
import GiftDappConnect from "./GiftDappConnection/GiftDappConnect/GiftDappConnect";
import GiftDappConnected from "./GiftDappConnection/GiftDappConnected/GiftDappConnected";
import GiftDappHeaderMenuItems from "./GiftDappHeaderMenuItems/GiftDappHeaderMenuItems";
import { GiftDappHeaderSidebar } from './GiftDappHeaderSidebar';
const style = makeStyles((theme) => ({
    root: {},
    toolbar: {
        backgroundColor: theme.colors.background,
        borderBottom: `solid 1px ${theme.colors.homeBorder}`,
    },
    container: {
        display: "flex",
        width: "100%",
        alignItems: "center",
        [theme.breakpoints.down("md")] : {

        }
    },
    gap: {
        display: "flex",
        flexGrow: 1,
    },
    logo: {
        height: 25,
        width: 25,
        cursor: "pointer",
    },
    companyNameContainer: {
        marginLeft: 10,
        cursor: "pointer",
        [theme.breakpoints.down("sm")]: {
            // display: "none",
        },
    },
    companyName: {
        fontSize: 18,
        fontWeight: "bold",
        color: theme.colors.text,
    },
    appName: {
        color: theme.colors.homeLiner,
    },
    alignRight: {
        justifyContent: "flex-end",
    },
    menuIcon : {
        color : !theme.isDark ? theme.colors.textSecondary : theme.colors.textBackground,
    },
    menuIconContainer : {
        border : `solid 1px ${theme.colors.homeBorder}`,
        borderRadius : 5,
        padding : 10,
        margin : "0px 2px",
        [theme.breakpoints.up("sm")] : {
            margin : "0 5px"
        }
    },
    connect : {
        [theme.breakpoints.down("sm")] : {
            display : "none"
        }
    },
}));
const GiftDappHeader = withWidth()(function _GiftDappHeader(props) {
    const classes = style(),
        { width } = props,
        { isDark } = useTheme(),
        [initialAccount, setInitialAccount] = useState(null),
        [toggleDrawer, setDrawerToggle] = useState(false),
        dispatch = useDispatch(),
        path = history.location.pathname,
        walletReducer = useSelector((state) => state[WALLET_REDUCER_NAME]),
        {
            [walletStrings.address]: address,
            [walletStrings.networkId]: networkId,
        } = walletReducer,
        isEdit = useSelector(
            (state) => state[GIFT_REWARD_REDUCER_NAME][giftRewardStrings.isEdit]
        ),
        headerReducer = useSelector((state) => state[HEADER_REDUCER_NAME]),
        { [headerStrings.showDashboard]: showDashboard } = headerReducer;

    const [getUserRewards, { loading, error, data }] = useLazyQuery(
        getRewards("_id")
    );

    useEffect(() => {
        getUserRewards({
            variables: {
                networkId: String(networkId),
                filter: { creator: address },
            },
        });
        if (!initialAccount) {
            return setInitialAccount(address); //the first account to be connected when the page loads;
        }
        if (!address || (address && address !== initialAccount)) {
            // this means, the user loged out or witched into another account
            // if the previous account is on dashboard, or currently viewing an event, redirect to home
            if (
                path.startsWith("/app/dashboard") ||
                path.startsWith("/app/dashboard/view/")
            )
                goHome();
        }
    }, [address]);

    useEffect(() => {
        // do nothing if reward has not been fetched
        if (!data?.rewards) return;
        if (data?.rewards && data.rewards[0]) {
            headerActions.payload({
                [headerStrings.showDashboard]: true,
            })(dispatch);
        } else {
            headerActions.payload({
                [headerStrings.showDashboard]: false,
            })(dispatch);
        }
    }, [data?.rewards]);

    const switchTheme = () => {
        const theme = localStorage.getItem(
            GIFT_DAPP_LOCAL_STORAGE_VAR.THEME_TYPE
        );
        localStorage.setItem(
            GIFT_DAPP_LOCAL_STORAGE_VAR.THEME_TYPE,
            theme === "light" ? "dark" : "light"
        );
        giftDappUtilActions.quickRefresh(dispatch);
    };

    const goHome = () => {
        window.location.href = "/v2";
    };

    return (
        <div className={classes.root}>
            <AppBar
                elevation={0}
                classes={{
                    colorPrimary: classes.toolbar,
                }}
            >
                <Toolbar>
                    <div className={classes.container}>
                            <img
                                src="/rigel_icon.png"
                                className={classes.logo}
                                onClick={goHome}
                            />
                        {address /* && ["xs", "sm"].indexOf(width) === -1 */ &&
                            <div
                                className={classes.companyNameContainer}
                                onClick={goHome}
                            >
                                <div className={classes.companyName}>
                                    Rigel Protocol
                                </div>
                                <div className={classes.appName}>GiftDapp</div>
                            </div>}
                        {["xs", "sm"].indexOf(width) !== -1 && !address && <div style={{marginLeft : 20}}>
                                <GiftDappConnect/>
                            </div>
                        }
                        <Hidden 
                            smDown
                        >
                            <GiftDappHeaderMenuItems
                                showDashboard={showDashboard}
                                path={path}
                                isEdit={isEdit}                                
                            />
                        </Hidden>
                        <div className={classes.gap} />
                        <Hidden 
                            mdUp
                        >
                            <Tooltip title="Menu" arrow>
                                <IconButton  onClick={() => setDrawerToggle(!toggleDrawer)} 
                                    className={classes.menuIconContainer}>
                                    <Menu fontSize="small" className={classes.menuIcon}/>
                                </IconButton>
                            </Tooltip>
                        </Hidden>
                        <div className={classes.connect}>
                            {!address && <GiftDappConnect/>}
                        </div>
                        {address && <GiftDappConnected switchTheme={switchTheme}/>}
                        {
                            ((!address && ["xm"].indexOf(width) !== -1) || ["md", "lg", "xl"].indexOf(width) !== -1) && 
                            <GiftDappConnection switchTheme={switchTheme} />
                        }
                        <GiftDappHeaderSidebar
                            openDrawer={toggleDrawer}
                            menuOptions={{
                                showDashboard, 
                                path,
                                isEdit,
                                toggleDrawer : () => setDrawerToggle(!toggleDrawer)
                            }}
                        />
                    </div>
                </Toolbar>
            </AppBar>
        </div>
    );
})
GiftDappHeader.propTypes = {};
export default GiftDappHeader;
export { GiftDappHeader };
