import { makeStyles } from '@material-ui/styles';
import React from 'react'
import {Link} from 'react-router-dom'


const style = makeStyles(({colors, breakpoints}) => ({
    root: {
        position: "relative",
        width: "inherit",
        textAlign: "left",
        marginBottom: "40px",
        borderRadius: "6px",
        background: colors.pageBackground,
        fontSize: "1.2rem",
        
        [breakpoints.down("md")]: {
            width: "inherit",
            margin: "4rem 1rem 0",
        },
        [breakpoints.down("sm")]: {
            width: "inherit",
            maxWidth: "25rem",
            margin: "0rem 0rem 0",
            // textAlign: "center"
        }
        
    },

    dataHead: {
        color: colors.pageColor,
        marginTop:"20px",
        marginLeft:"0px",
        marginRight:"10px",
        fontFamily: "Clash Display",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "20px",
        lineHeight: "20px",
        color: colors.pageColor,
        margin: 0,
        [breakpoints.down("md")]: { 
            fontSize: "20px", 
        },
        [breakpoints.down("sm")]: {
            fontSize: "18px",
        },
    },

    
   

    sectionItem: {
        color: colors.funColor,
        fontFamily: "DM Sans",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "16px",
        lineHeight: "24px",
        display: "block",
        margin: "1.5rem 0",
        transition: "all ease-in-out",
        "&:hover": {
            opacity: .8,
        }
        
    },

}))



export default function GiftDappBluePrintItems({item}) {
    const classes = style();
    return(
        <div className = {classes.root}>
                <p className = {classes.dataHead}>{item.title}</p>
                {item.data.map((item, index) => {
                   return item.link.startsWith("/") ?
                   <Link to  = {item.link} key = {index}  className = {classes.sectionItem}>{item.link_name}</Link> :
                   <a href = {item.link} target = "_blank" key = {index}  className = {classes.sectionItem}>{item.link_name}</a>
                })}
        </div>
    );
}
