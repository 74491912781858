import { makeStyles } from '@material-ui/styles';
import React from 'react'
import GiftDappProjects from "./GiftDappProjects/GiftDappProjects"
import GiftDappInfluencers from "./GiftDappInfluencers/GiftDappInfluencers"
import GiftDappEveryone from "./GiftDappEveryone/GiftDappEveryone"


const styles = makeStyles(({colors, breakpoints})=>({
    
    root: {
        color: colors.pageColor,
        textAlign: "center",
        position: "relative",
        transformStyle: "preserve-3d",
        minHeight: "2143px",
        paddingTop: "5rem",
        marginBottom:"100px",
        boxSizing: "border-box",
        backgroundColor:colors.pageBackground,
        [breakpoints.down('md')]: {
            paddingRight: "2.5rem",
            paddingLeft: "2.5rem",
        },
        [breakpoints.down('sm')]: {
            paddingRight: "1rem",
            paddingLeft: "1rem",
            minHeight: "501px",
        }
    },
    jumboHeading: {
        fontSize: "56px",
        fontFamily: "Clash Display",
        fontStyle: "normal",
        fontWeight: 600,
        color: colors.pageColor,
        marginBottom: "1rem",
        marginTop: 0, 
        whiteSpace: "nowrap",
        [breakpoints.down('lg')]: {
            fontSize: "56px",
        },
        [breakpoints.down('sm')]: {
            fontSize: "23px",
        }
    },
    jumboText: {
        fontSize: "18px",
        fontFamily: "DM Sans",
        fontStyle: "normal",
        fontWeight: "normal",
        color: colors.pageColor,
        lineHeight: "28px",
        opacity: .8,
        marginBottom: "2rem",
        maxWidth: "53%",
        margin: "0 auto 1.2rem",
        [breakpoints.down('xl')]: {
            fontSize: "20px",
        },
        [breakpoints.down('sm')]: {
            fontSize: "16px",
            maxWidth: "100%",
        }
    },
   
    
}))

export default function GiftDappPIE() {

    const classes = styles();
    return(
        <section className = {classes.root}>
                <h1 className = {classes.jumboHeading}>
                Projects.Influencers.Everyone
                </h1>
                <p className = {classes.jumboText}>
                    Now, you get to grow your followers and users by rewarding them tokens in fun ways with exciting games. 
                </p>
               <GiftDappProjects />
               <GiftDappInfluencers />
               <GiftDappEveryone />
            </section>
    );
}