import React, { useEffect, useState } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { breadCrumbActions, breadCrumbStrings, CustomDialog } from '../../../includes';
import { useDispatch } from 'react-redux';
import createBrowserHistory from 'history/createBrowserHistory';
import { styled } from '@mui/system';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled';
import ButtonUnstyled from '@mui/base/ButtonUnstyled';
import useGAEventsTracker from './../../../../giftdapp/hooks/useGAEventsTracker';
import { appValues } from '../../../helpers';

const Tab = styled(TabUnstyled)`
  font-family: IBM Plex Sans, sans-serif;
  color: #7599BD;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: bold;
  background-color: transparent;
  width: 100%;
  padding: 12px 16px;
  margin: 6px 6px;
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: #ABE1ED;
  }

  &:focus {
    color: #7599BD;
    border-radius: 3px;
    outline: 2px solid #ABE1ED;
    outline-offset: 2px;
  }

  &.${tabUnstyledClasses.selected} {
    background-color: #ABE1ED;
    color: white;
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabsList = styled(TabsListUnstyled)`
  min-width: 160px;
  background-color: transparent;
  border-radius: 6px;
  border: 1px solid #ABE1ED;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
`;

const style = makeStyles(({breakpoints, colors, isDark}) => ({
    root : {
      background : colors.background,
      borderRadius : 10,
      padding : "20px 100px",
      [breakpoints.down("xs")] : {
          padding : 20

      },
      [breakpoints.down("sm")] : {
          padding : "20px 50px"
      },
    },
    header : {
        fontSize : 18,
        fontWeight : "bold",
        display : 'flex',
        justifyContent : 'center',
        marginBottom : 20,
        marginTop : 50,
    },
    breaker : {
        marginTop : 50,
        [breakpoints.down("xs")] : {
            marginTop : 50,
        }
    },
    description : {
        display : "flex",
        fontSize : 12,
        // flexDirection : "column",
        textAlign : "center",
        alignItems : "center",
        justifyContent : "center",
        color : colors.textSecondary,
        // padding : "20px 50px",
        // [breakpoints.up("sm")] : {
        //     padding : "20px 100px",
        // }
    },
    imageContainer : {
        display : "flex",
        justifyContent : "center",
        alignItems : "center",
    },
    image : {
        height : 300,
        width : 300,
    },
    categories : {
      
        fontSize : 12,
        [breakpoints.down("xs")] : {
            marginTop : 50,
            textAlign : "center",
            marginBottom : 100,
        }
    },
    type : {

    },
    text : {
        color : colors.textSecondary,
        margin : "10px 0"
    },
    link : {
        color : colors.accent,
        textDecoration : "underline",
        cursor : "pointer",
    },
    modalDiv: {
      background : colors.background,
      borderRadius : 10,
      padding : "20px 20px",
      [breakpoints.down("xs")] : {
          padding : 20

      },
      [breakpoints.down("sm")] : {
          padding : "20px 50px"
      },
    },
    heading: {
      fontWeight: 'bold',
      fontSize: "1rem",
      color: isDark ? '#F1F5F8' : '#333333',
      lineHeight: "1.75rem",
      textAlign: 'center',
    },
    modalHeader: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      marginBottom: "1.5rem",
    },
    modalBody: {
      display: "flex",
      flexDirection: "column",
      textAlign: 'center',
    },
    dialogPaper : {
      background : colors.background,
      border : "solid 1px " + colors.accentSecondary,
      color : colors.text,
      overflow : "visible",
    },
    dialogClose : {
      background : colors.text,
      top : -40,
      padding : 5,
      "&:hover" : {
          background : colors.accent,
          color : colors.textSecondary
      }
    },
    switchBtn: {
      background: '#ABE1ED',
      cursor: "pointer",
      border: '1px solid',
      borderColor: '#ABE1ED',
      boxSizing: 'border-box',
      boxShadow: '0px 1px 7px rgba(41, 45, 50, 0.08)',
      borderRadius: '6px',
      padding: '16px 0',
      color: '#fff',
      width: '100%',
      fontSize: '16px',
      fontWeight: 'bold',
      whiteSpace: "nowrap",
      textTransform: 'capitalize',
      marginTop: '18px',
      marginRight: '14px',
      '&:hover': {
        background:'#23D3ED',
        color: '#fff'
      }
    }
}))
function GiftDappRewardHome({rewardAction}) {
    const classes = style(),
    isProduction = process.env.NODE_ENV === "production",
    dispatch = useDispatch();
    useEffect(() => {
        breadCrumbActions.payload({
            [breadCrumbStrings.visible] : false,
        })(dispatch);
    }, [])
    const history = createBrowserHistory({forceRefresh:true});
    const switchVersion = version => {
        if(isProduction)
           window.location.href = version;
        else history.push(version);
    };
    const [switchNotice, setSwitchNotice] = useState(true);
    const GAEventsTracker = useGAEventsTracker(),
    v1Link = isProduction ? appValues.v1Url : '/',
    v2Link = isProduction ? appValues.v2Url : '/v2/app'

    return<div className={classes.root}>
        <Grid container>
          <CustomDialog
            within
            classes={{
              dialogPaper : classes.dialogPaper,
              _close : classes.dialogClose,
            }}
            open={switchNotice}
            close={() => setSwitchNotice(false)}
          >
            <div className={classes.modalDiv}>
                <div className={classes.modalHeader}>
                    <div className={classes.heading}>Switch Versions</div>
                </div>
                <div className={classes.modalBody}>
                    This is an old version of the Gift Dapp, you can switch over to a newer version by clicking on the button below.
                    <ButtonUnstyled className={classes.switchBtn} onClick={() => { switchVersion(v2Link); GAEventsTracker("switch_to_v2", "notice modal"); }}>
                      Switch to V2
                    </ButtonUnstyled>
                </div>
              </div>
          </CustomDialog>
            <TabsUnstyled defaultValue={0} style={{marginLeft: 'auto'}}>
              <TabsList>
                <Tab onClick={() => switchVersion(v1Link)}>V1</Tab>
                <Tab onClick={() => { switchVersion(v2Link); GAEventsTracker("switch_to_v2", "toggle button");}}>V2</Tab>
              </TabsList>
            </TabsUnstyled>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <>
                    <div className={classes.header}>
                        Gift Rewards
                    </div>
                    <div className={classes.description}>
                        We help you create fun ways for your followers and users to get rewards from you.
                        You have the opportunity to create rewards as an Influencer for your followers or as a Project for your users.
                        It is a great way to reward your followers and users for their loyalty!
                    </div>
                </>
            </Grid>
        </Grid>
        <div style={{marginTop : 20}}/>
        <Grid container>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <div className={classes.imageContainer}>
                    <img src="/assets/landing.png" className={classes.image} alt="landing image"/>
                </div>
            </Grid>
            <Grid item style={{alignItems : 'center', display : "flex"}} xs={12} sm={6} md={6} lg={6} xl={6}>
                <div className={classes.categories}>
                    <div className={classes.type}>
                        <div className={classes.title}>
                            For Influencers
                        </div>
                        <div className={classes.text}>
                            <span>Become a reputable influencer.</span>
                            <span> Gift crypto to your followers and fans</span>
                        </div>
                        <div className={classes.link}
                            onClick={() => rewardAction(false)}
                        >
                            Create gift rewards as an influencer
                        </div>
                    </div>
                    <div style={{marginBottom : 50}}/>
                    <div className={classes.type}>
                        <div className={classes.title}>
                            For Projects
                        </div>
                        <div className={classes.text}>
                            <span>Reward your users in a funny way.</span>
                            <span>save time and money</span>
                        </div>
                        <div className={classes.link}
                            onClick={() => rewardAction(true)}
                        >
                            Create gift rewards as a project
                        </div>
                    </div>
                </div>
            </Grid>
        </Grid>
    </div>
}
GiftDappRewardHome.propTypes = {

}
export default GiftDappRewardHome;
export {GiftDappRewardHome};
