import { Grid, makeStyles } from '@material-ui/core'
import React, { useState } from 'react'
import CustomInputFields from '../../../../includes/CustomInputFields/CustomInputFields';
const style = makeStyles(({colors, isDark, breakpoints}) => ({
    root : {
        fontSize : 12,
        display : "flex",
        padding : "20px 0px",
        // borderBottom : "solid 1px " + colors.textSecondary,
        alignItems : 'center',
        [breakpoints.down("sm")] : {
            border : "none"
        }
    },
    label : {
        marginBottom : 10,
        display : 'flex',
        alignItems : "center",
        height : "100%"
    },
    inputContainer : {
        display : "flex",
        margin : 0
    },
    prefix : {
        background : colors.background,
        display : "flex",
        alignItems : "center",
        padding : 10,
        color : !isDark ? colors.text : colors.textBackground,
        border : `solid 1px ${colors.borderLines}`,
        fontSize : 12,
        width : 100,
        borderRadius : "5px 0 0 5px",
        // minWidth : 100,
    },
    formField : {
        borderRadius : 5,
        [breakpoints.up("sm")] : {
            maxWidth : 400,
        }
    },
    prefix_formField : {
        [breakpoints.up("sm")] : {
            maxWidth : 400 - 100,
        }
    },
    notchedOutline : {
        borderRadius : 5
    },
    _notchedOutline : {
        borderRadius : "0 5px 5px 0"
    },
    instruction : {
        color : colors.textSecondary,
        fontSize : 12,
        marginTop : 5,
        fontStyle: "italic",
    }
}))
export default function GiftDappRewardSetupInput ({prefix, instruction, ...props}) {
    const classes = style(),
        {label} = props;
    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <div className={classes.label}>{label}</div>
                </Grid>
                <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                    {prefix ? <div className={classes.inputContainer}>
                        <div className={classes.prefix}>{prefix}</div>
                        <CustomInputFields 
                            field={props}
                            classes={{
                                formField : classes.prefix_formField,
                                notchedOutline : classes._notchedOutline
                            }}
                        />
                    </div> : <CustomInputFields 
                        classes={{
                            formField : classes.formField,
                            notchedOutline : classes.notchedOutline
                        }}
                        field={props}/>}
                    {instruction && <div className={classes.instruction}>{instruction}</div>}
                </Grid>
            </Grid>
        </div>
    )
}