export const REWARD_FIELD_NAMES = {
    NO_OF_WHEEL : "wheels",
    CLAIMS_PER_USER : "claims",
    CLAIM_PERIOD : 'duration',
    SPINE_FIELD_VALUES : "spinFieldValues",
    PROJECT_DETAILS : "projectDetails",    
    IS_PROJECT : "isProject",
    ACTIVE_PERIOD : "activePeriod",
    DYNAMIC_TEST_ARRAY : "dynamic_texts",
}
export const SETUP_TABS_CONFIG = {
    PROFILE : "PROFILE",
    PROJECT_DETAILS : "PROJECT_DETAILS",
    SOCIAL_LINKS : "SOCIAL_LINKS",
    GAME_SETUP : "GAME_SETUP",
    SPIN_PREVIEW : "SPIN_PREVIEW",
}
export const SETUP_TAB_STAGE_MAP = {
    [SETUP_TABS_CONFIG.PROFILE] : 1,
    [SETUP_TABS_CONFIG.PROJECT_DETAILS] : 2,
    [SETUP_TABS_CONFIG.SOCIAL_LINKS] : 3,
    [SETUP_TABS_CONFIG.GAME_SETUP] : 4,
    [SETUP_TABS_CONFIG.SPIN_PREVIEW] : 5
}
export const giftRewardStrings = {
    rewardTokens : "rewardTokens",
    rewardData : "rewardData",
    creatorId : "creatorId",
    rewardCreator : "rewardCreator",
    isEdit : "isEdit",
    setupTab : "setupTab",
    setupPosition : "setupPosition",
    selectedRewardTokens : "selectedRewardTokens",
    defaultTokenLoaded : "defaultTokenLoaded",
    profileOption : "profileOption",
}
export const giftRewardConstants = {
    SET_GIFT_REWARDS : "SET_GIFT_REWARDS",
    RESET_GIFT_REWARD_SPIN_DATA : "RESET_GIFT_REWARD_SPIN_DATA",
    SET_SELECTED_TOKEN : "SET_SELECTED_TOKEN",
}
export const generateSpinFields = (spinNumber, spinFields) => {
    if(spinNumber) {
        if(spinFields.length > spinNumber){
            spinFields.splice(spinNumber)
        }
        for (let i = 0; i < spinNumber; i++) {
            if(!spinFields.find(item => item.spinIndex === i))
                spinFields.push({spinIndex : i})
        }
    }
    return spinFields;
}
const initialState = {
    [giftRewardStrings.rewardData] : {
        [REWARD_FIELD_NAMES.ACTIVE_PERIOD] : 1,
        [REWARD_FIELD_NAMES.NO_OF_WHEEL] : 12,
        [REWARD_FIELD_NAMES.CLAIMS_PER_USER] : 1,
        [REWARD_FIELD_NAMES.CLAIM_PERIOD] : 0,
        [REWARD_FIELD_NAMES.SPINE_FIELD_VALUES] : generateSpinFields(12, []),
    },
    [giftRewardStrings.rewardCreator] : {},
    [giftRewardStrings.profileOption] : null,
    [giftRewardStrings.selectedRewardTokens] : [],
    [giftRewardStrings.setupTab] : SETUP_TABS_CONFIG.PROFILE,
    [giftRewardStrings.setupPosition] : SETUP_TAB_STAGE_MAP[SETUP_TABS_CONFIG.PROFILE],
}
export const giftRewardReducer = (state=initialState, action) => {
    const {payload, type} = action;
    switch(type){
        case giftRewardConstants.SET_GIFT_REWARDS : 
            return {...state, ...payload};
        case giftRewardConstants.SET_SELECTED_TOKEN : 
            const selectedRewardToken = state[giftRewardStrings.selectedRewardTokens],
            tokenInfo = selectedRewardToken?.find(item=>item.address === payload?.address);
            if(!tokenInfo){
                return {
                    ...state, 
                    [giftRewardStrings.selectedRewardTokens] : [
                        ...state[giftRewardStrings.selectedRewardTokens],
                        payload
                    ],
                    ...payload
                };
            }
            return state;            
        case giftRewardConstants.RESET_GIFT_REWARD_SPIN_DATA : 
            const {[giftRewardStrings.rewardData] : rewardData, ...others} = payload || {};
            return {
                ...initialState, ...others,
                [giftRewardStrings.rewardData] : {
                    ...initialState[giftRewardStrings.rewardData],
                    ...rewardData
                }
            };
        default : return {...state};
    }
}
export const GIFT_REWARD_REDUCER_NAME = "giftRewardReducer";