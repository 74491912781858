import { makeStyles } from '@material-ui/styles';
import React from 'react'
import { Grid } from '@material-ui/core';
import GiftDappBluePrintItems from "./GiftDappBluePrintItems/GiftDappBluePrintItems"
import {giftDappRouteNames} from '../../../../giftdapp/utils'
import {landingRouteNames} from '../../../utils'


const style = makeStyles(({colors, breakpoints}) => ({
    root: {
        padding: "5rem",
        marginBottom: "0rem",
        [breakpoints.down('sm')]: {
            // textAlign: "center",
            padding: "1.5rem",
        },
    },
    heading: {
        fontFamily:"Clash Display",
        fontStyle: "normal",
        paddingBottom:"10px",
        fontWeight: 600,
        fontSize: "24px",
        lineHeight: "32px",
        color: colors.pageColor,
        marginBottom: 0,
        [breakpoints.down('xl')]: {
            fontSize: "24px"
        },
        [breakpoints.down('sm')]: {
            fontSize: "20px"
        },
    },
    dataContainer: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "6rem",
        [breakpoints.down(1690)]: {
            flexWrap: "wrap",
            marginTop: "3rem"
        },
        [breakpoints.down("sm")]: {
            justifyContent: "center",
            textAlign:"center"
        },
    },

    headerLine:{
        border: `1px solid ${colors.LineColor}` 
    }
}))


export default function GiftDappBluePrint() {

    const classes = style();

    // const data = [
    //     { dataHead: "Create Event", data1: "Influencer", data2: "Project", data3: "Everyone Else"},
    //     { dataHead: "View Events", data1: "All Events", data2: "Active Events", data3: "Ended Events"},
    //     { dataHead: "Rewards", data1: "Claim Reward", data2: "Share on Social Media", data3: ""},
    //     { dataHead: "Legal", data1: "Design Compliance", data2: "Privacy Policy", data3: "Terms & Conditions"},
    //     { dataHead: "Contact", data1: "Twitter", data2: "Telegram", data3: "Medium"},
    //     ];

    const data = [
        { title: "Create Event", data: [
            {link_name: "Influencer", link: giftDappRouteNames().rewardSetup},
            {link_name: "Project", link: giftDappRouteNames().rewardSetup},
            {link_name: "Everyone Else", link: giftDappRouteNames().events}
        ]},
        { title: "View Events", data: [
            {link_name: "All Events", link: giftDappRouteNames().events},
            {link_name: "Active Events", link: giftDappRouteNames().events},
            {link_name: "Ended Events", link: giftDappRouteNames().events}
        ]},
        { title: "Rewards", data: [
            {link_name: "Claim Reward", link: landingRouteNames().app},
            {link_name: "Share on Social Media", link: landingRouteNames().app}
        ]},
        { title: "Legal", data: [
            {link_name: "Design Compliance", link: "https://rigelprotocol.com/design-compliance"},
            {link_name: "Privacy Policy", link: "https://rigelprotocol.com/privacy-policy"},
            {link_name: "Terms & Conditions", link: "https://rigelprotocol.com/terms-and-condition"}
        ]},
        { title: "Contact", data: [
            {link_name: "Twitter", link: "https://twitter.com/rigelprotocol"},
            {link_name: "Telegram", link: "https://www.t.me/rigelprotocol"},
            {link_name: "Medium", link: "https://medium.com/rigelprotocol"}
        ]},
    ];

    return(
        <section className = {classes.root}>
            <h2 className = {classes.heading}>
            GiftDApp BluePrints
            </h2>
            <hr className={classes.headerLine} />
            <div className = {classes.dataContainer}>
                <Grid container spacing={2}>
                    {data && data.map((item, index) => {
                        // const { dataHead, data1, data2, data3} = item;
                        return <Grid key={index} item xs={6} sm={6} md={4} lg={4} xl={4} >
                            <GiftDappBluePrintItems key = {index} item = {item} />
                        </Grid>
                    })}
                </Grid>
            </div>
        </section>
    )

}