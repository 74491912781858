import { List, ListItem, ListItemText, makeStyles } from '@material-ui/core'
import React, { useCallback, useEffect, useState } from 'react'
import classNames from 'classnames';
import { getContractInstance, giftContractActions, giftDappRouteNames, NETWORKS, tokenContractActions } from '../../../../utils';
import { Link } from 'react-router-dom';
import { ArrowDropDown, Close } from '@material-ui/icons';
import useGAEventsTracker from '../../../../hooks/useGAEventsTracker';
import walletUtil from '../../../../utils/web3/walletUtil';
import { useDispatch, useSelector } from 'react-redux';
import { walletStrings, WALLET_REDUCER_NAME } from '../../GiftDappWallets';
import { GiftDappNetworks } from '../GiftDappConnection/GiftDappNetworks';
import { CustomDialog, errorToast } from '../../../../includes';
import walletConnection from '../../../../utils/web3/walletConnection';
const style = makeStyles(({colors, breakpoints, isDark}) => ({
    root : {
        
    },
    link: {
        color: colors.text,
        textDecoration: "none",
        fontSize: 14,
        [breakpoints.up("sm")]: {
            padding: "0px 10px",
        },
        [breakpoints.down("md")] : {
            
        },
    },
    active: {
        color: colors.homeLiner,
    },
    menuContainer: {
        marginLeft: 20,
        display: "flex",
        [breakpoints.down("xs")]: {
            justifyContent: "space-around",
            // width: "100%",
        },
    },
    sideMargin: {
        margin: "0px 10px",
    },
    close: {
        display: "none",
        [breakpoints.down("sm")] : {
          display : "flex",
          borderRadius: `6px`,
          justifyContent: `center`,
          alignItems: `center`,
          padding: "12px",
          width: "21.5px",
          height: "21.5px",
          color: colors.text,
          boxSizing: `border-box`,
          border: `1px solid ${colors.homeBorder}`,
        },
    },
    listIten: {
        textDecoration: "none",
        color: colors.text,
        cursor: "pointer"
    },
    balance : {
        display : "flex",
        background : !isDark ? "" : colors.secondary,
        border: `1px solid ${colors.homeBorder}`,
        color: !isDark ? colors.text : colors.homeLiner,
        padding: 12,
        alignItems : "center",
        borderRadius : 5,
    },
    dialogPaper : {
        background : colors.background,
        border : "solid 1px " + colors.accentSecondary,
        color : colors.text,
        overflow : "visible",
    },
    dialogClose : {
        background : colors.text,
        top : -40,
        padding : 5,
        "&:hover" : {
            background : colors.accent,
            color : colors.textSecondary
        }
    },
}))
export default function GiftDappHeaderMenuItems ({ showDashboard, path, isEdit, isSidebar, toggleDrawer }) {
    const classes = style(),
    dispatch = useDispatch(),
    GAEventsTracker = useGAEventsTracker(),
    [networkDialog, setNetworkDialog] = useState(false),
    [rigelBalance, setRGPBalance] = useState(false),
    walletReducer = useSelector(state => state[WALLET_REDUCER_NAME]),
    { 
        [walletStrings.address] : address,
        [walletStrings.networkId] : networkId,
        [walletStrings.networkType] : networkType,
        [walletStrings.networkWallet] : networkWallet,
        [walletStrings.web3] : web3,
        [walletStrings.contractAddresses] : contractAddresses,
    } = walletReducer,
    networkInfo = walletUtil.networkList.find(item => item.networkId === networkId),
    logo = networkInfo?.image || "/assets/bnb.png",
    networkName = walletUtil.getTruffleNetworkName(networkInfo?.networkId) || "Dev",
    onNetworkSelect = useCallback(async(networkId)=>{
        if(!address) return;
        try{
            const _id = walletUtil.getTruffleNetworkName(networkId);
            const provider = web3._provider;
            if(!NETWORKS[_id]) throw new Error("Network metadata not configured");
            else{
                await walletUtil.switchNetwork(provider, NETWORKS[_id].chainId, NETWORKS[_id]);
                await walletConnection(
                    contractAddresses, 
                    networkWallet, 
                    null, true
                );
            }
        }catch(e){
            console.error(e)
        }
    },[]),
    rgpTokenAmount = amount => {
        if(isNaN(amount)) amount = 0;
        const tenseFigure = getTenseLiteral(Number(amount), 1000);
        let unit = "";
        switch( tenseFigure ) {
            case 1 : unit = "K"; break;
            case 2 : unit = "M"; break;
            case 3 : unit = "B"; break;
            case 4 : unit = "T"; break;
        }
        return Math.round(amount / 10 ** (tenseFigure * 3)) + unit;
        // return String(amount).length > 6 ? String(Number(amount).toLocaleString()).substr(0, 6) + "..." : Number(amount).toLocaleString()
    }, 
    getTenseLiteral = (amount, divisor) => {
        let result = 0;
        if(amount < divisor) result = 0;
        else {
            result++;
            result += getTenseLiteral(amount, divisor * 1000);
        }
        return result;
    },
    list = [
        {
            label : "Reward",
            className : classNames(
                classes.link,
                !isEdit &&
                    [
                        giftDappRouteNames().reward,
                        giftDappRouteNames().rewardSetup,
                    ].indexOf(path) !== -1
                    ? classes.active
                    : "",

                !showDashboard ? classes.sideMargin : ""
            ),
            to : giftDappRouteNames().reward
        },{
            label : "Event",
            className : classNames(
                classes.link,
                giftDappRouteNames().events === path ||
                    path.includes(
                        giftDappRouteNames().eventView_
                    )
                    ? classes.active
                    : "",
                !showDashboard ? classes.sideMargin : ""
            ),
            to : giftDappRouteNames().events
        }, {
            label : "Reward Card",
            className : classNames(
                classes.link,
                giftDappRouteNames().specialReward ===
                    path ||
                    path.includes(
                        giftDappRouteNames().specialReward
                    )
                    ? classes.active
                    : "",
                !showDashboard ? classes.sideMargin : ""
            ), 
            to : giftDappRouteNames().specialReward,
        }, {
            label : "NFT",
            className : classNames(
                classes.link,
                giftDappRouteNames().nft === path ||
                    path.includes(giftDappRouteNames().nft)
                    ? classes.active
                    : "",
                !showDashboard ? classes.sideMargin : ""
            ),
            to : giftDappRouteNames().nft
        }
    ]
    useEffect(()=>{
        (async() => {
            try{
                const rigelBalance =
                    await tokenContractActions(
                        await getContractInstance(
                            web3, null,
                            await giftContractActions().RGPTokenAddress()
                        )
                    ).myBalance()
                    setRGPBalance(rigelBalance)
            }catch({message}){
                errorToast(message)(dispatch);
            }
        })();
    }, [address])
    if(showDashboard) list.unshift({
        label : "Dashboard",
        className : classNames(
            classes.link,
            (giftDappRouteNames().rewardSetup ===
                path &&
                isEdit) ||
                path.includes(
                    giftDappRouteNames().rewardView_
                ) ||
                giftDappRouteNames().dashboard ===
                    path
                ? classes.active
                : ""
        ),
        to : giftDappRouteNames().dashboard
    })
    return (
        <div
            className={classNames(
                classes.menuContainer,
                !showDashboard ? classes.alignRight : ""
            )}
        >
            {isSidebar ? <div>
                <List style={{marginTop: 35}}>
                    <div style={{display: "flex", justifyContent: "flex-start", marginLeft: "16px", marginBottom: 29.25, cursor: "pointer"}}>
                        <div onClick={toggleDrawer} className={classNames(classes.close)}>
                            <Close fontSize="small"/>
                        </div>
                    </div>
                    {list.map((item, index) => {
                        const {label, to, className} = item;
                        return <ListItem 
                            key={index}
                            component={Link} 
                            className={classNames(className/* , classes.listIten */)} 
                            to={to}>
                        <ListItemText primary={label} />
                      </ListItem>
                    })}
                    {address && <ListItem>
                        <div className={classNames(classes.balance, classes.cursor)} title={networkName} 
                            onClick={()=> { 
                                setNetworkDialog(true); 
                                GAEventsTracker("display_network_modal", "header"); 
                            }}>
                            {logo && <img src={logo} className={classes.midiumlogo} alt={"Binance"}/>}  
                            {networkName}
                            <ArrowDropDown className={classes.iconDd}/>
                        </div>
                    </ListItem>}
                    {address && <ListItem>
                        <div style={{padding : 15}} className={classes.balance} title={`${Number(rigelBalance).toLocaleString()} RGP`}>
                            {rgpTokenAmount(rigelBalance)} RGP
                        </div>
                    </ListItem>}
                </List>
                </div> : list.map((item, index) => {
                const {label, className, to} = item;
                return <Link key={index} className={className} to={to}>{label}</Link>
            })}
            <CustomDialog
                within
                classes={{
                    dialogPaper : classes.dialogPaper,
                    _close : classes.dialogClose,
                }}
                open={networkDialog}
                close={() => setNetworkDialog(false)}
            >
                <GiftDappNetworks
                    network={networkType}
                    onSelect={onNetworkSelect}
               />
            </CustomDialog>
        </div>
    )
}
export { GiftDappHeaderMenuItems }