import { Divider, Grid, TextField, withStyles } from '@material-ui/core'
import React from 'react'
import { userExists } from '../../../utils';
import CustomInputFields from '../../../includes/CustomInputFields/CustomInputFields';
import { Settings } from '@material-ui/icons';
import { CustomButton } from '../../../includes';
import { giftDappProfileStrings } from '../config';

const style = ({colors, breakpoints}) => ({
    root : {
    },
    header : {},
    subheader : {},
    divider : {},
    imageContainer : {
        marginTop : 30,
        display : "flex",
        justifyContent : "space-between",
        alignItems : "center"
    },
    imageIcon : {
        color : colors.textLight1,
    },
    imageWrapper : {
        background : colors.black,
        margin : 10,
    },
    imageBtn : {
        background : colors.black,
        color : colors.textLight1
    },
    verified : {
        color : colors.verified,
        display : "flex",
        alignItems : "center",
    },
    verifiedIcon : {
        margin : 10,
    },
    profileContainer : {
        marginTop : 20,
    },
    inputItem : {
        margin : "10px 0",
        color : "white",
    },
    inputLabel : {
        color : colors.text,
    },
    btnContainer : {
        display : "flex",
        justifyContent : "flex-end",
        marginTop : 30,
    },
    saveBtn : {
        padding : "10px 30px"
    },
    verifiedText : {
        [breakpoints.down("sm")] : {
            display : "none",
        }
    },
    notchedOutline : {
        
    },
    input : {
        color : colors.textSecondary,
    }
}),
{username, lastname, firstname, about} = giftDappProfileStrings,
profileInfo = [
    {name : username, label : "Username", fullWidth : true},
    {name : lastname, label : "Last Name"},
    {name : firstname, label : "First Name"},
    {name : about, label : "About", fullWidth : true, multiline : true, rows : 6},
]
export default withStyles(style)(function GiftDappProfileProfile ({
    classes, isNew, data={}, onRemove=()=>{}, onChange=()=>{},
    onBlur=()=>{}, onSave=()=>{}, updating,
}) {
    const {verified} = data || {},
    _onChange = name => ({target : {value}}) => {
        onChange(name, value);
    }
    return (
        <div className={classes.root}>
            {!isNew && <div className={classes.headerContainer}>
                <div className={classes.header}>Profile</div>
                <div className={classes.subheader}>This information will be displayed publically as your profile.</div>
            </div>}
            {!isNew && <Divider className={classes.divider}/>}
            <div className={classes.imageContainer}>
                <CustomInputFields
                    field={{
                        type : "dropzone",
                        mobileView : true,
                        button : {
                            name : "Add Display Photo"
                        },
                        onChange : files => onChange("image", files),
                        onRemove : () => onRemove("image"),
                        value : data["image"]
                    }}
                    classes={{
                        imageIcon : classes.imageIcon,
                        imageWrapper : classes.imageWrapper,
                        imageBtn : classes.imageBtn,
                    }}
                />
                {!isNew && verified && <div className={classes.verified}>
                    <Settings fontSize="large" className={classes.verifiedIcon}/>
                    <div className={classes.verifiedText}>Verified</div>
                </div>}
            </div>
            <div className={classes.profileContainer}>
                <Grid container spacing={2}>
                    {profileInfo.map((field, index) => {
                        const {fullWidth, name, label, ..._} = field;
                        field = _;
                        const gridSize = fullWidth ? {xs : 12, sm : 12, md : 12, lg :12, xl : 12} : 
                        {xs : 12, sm : 6, md : 6, lg :6, xl : 6};
                        return <Grid item {...gridSize} key={index}>
                            <div className={classes.inputLabel}>{label}</div>
                            <TextField
                                className={classes.inputItem}
                                InputProps={{
                                    classes : {
                                        notchedOutline : classes.notchedOutline,
                                        input : classes.input,
                                    }
                                }}
                                value={data[name] || ""}
                                fullWidth 
                                margin="dense"
                                variant="outlined"
                                onChange={_onChange(name)}
                                onBlur={() => onBlur(name, data[name])}
                                {...field}
                            />
                        </Grid>
                    })}
                </Grid>
            </div>
            {!isNew && <div className={classes.btnContainer}>
                <CustomButton
                    type="accent"
                    classes={{
                        btn : classes.saveBtn
                    }}
                    onClick={onSave}
                    loading={updating}
                >
                    Save
                </CustomButton>
            </div>}
        </div>
    )
});