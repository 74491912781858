import { makeStyles } from '@material-ui/styles';
import React from 'react'

const style = makeStyles(({colors, breakpoints}) => ({
    root: {
        position: "relative",
        width: "inherit",
        textAlign: "left",
        height: "216px",
        margin: 30,
        borderRadius: "6px",
        background: "#012645",
        fontSize: "1.2rem",
        [breakpoints.down("md")]: {
            width: "90%",
            margin: "auto",
        },
        [breakpoints.up("md")]: {
            width: "95%",
        height:"250px",
            margin: "auto",
        },
        [breakpoints.down("sm")]: {
            width: "inherit",
        }
        
    },

   

    dataPicture: {
        marginTop:"20px",
        marginLeft:"20px",
        width: "64px",
        height: "64px"
    },
    dataHead: {
        marginTop:"20px",
        marginLeft:"20px",
        fontFamily: "Clash Display",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "24px",
        lineHeight: "22px",
        collor: colors.pageColor,
        margin: 0,
        whiteSpace: "nowrap",
        [breakpoints.down("md")]: {
            fontSize: "18px",
            lineHeight: "20px",
        },
        [breakpoints.down("sm")]: {
            fontSize: "18px",
            lineHeight: "20px", 
        },
    },
    dataContent: {
        marginTop:"20px",
        marginLeft:"20px",
        marginRight:"20px",
        fontFamily: "DM Sans",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "16px",
        lineHeight: "24px",
        color: colors.pageColor,
        margin: 0,
        Width: "60%",
        [breakpoints.down("md")]: {
            fontSize: "14px",
            lineHeight: "20px",
            marginTop:"15px",
        },
        [breakpoints.down("sm")]: {
            fontSize: "14px",
            lineHeight: "20px",
            marginTop:"15px",
        },
    },

}))

export default function GiftDappNetworkCardItem({dataHead, dataContent, dataPicture}) {
    const classes = style();

    return(
        <div className = {classes.root}>
                <img src = {`/${dataPicture}.gif`} className = {classes.dataPicture} alt = "project pic" />
                <p className = {classes.dataHead}>{dataHead}</p>
                <p className = {classes.dataContent}>{dataContent}</p>
        </div>
    );
}