import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react'
import GiftDappNetworkItem from "./GiftDappNetworkItem/GiftDappNetworkItem";
import GiftDappNetworkCardItem from "./GiftDappNetworkCardItem/GiftDappNetworkCardItem";

const style = makeStyles(({colors, breakpoints}) => ({
    root: {
        position: "relative",
        textAlign: "center",
        padding: "5rem",
        marginBottom: "0rem",
        [breakpoints.down('sm')]: {
            textAlign: "center",
            padding: "1.5rem",
            height:"100%",
        },
    },
    heading: {
        fontFamily: "Clash Display",
        fontStyle: "normal",
        fontWeight: 600,
        
        fontSize: "48px",
        color: colors.pageColor,
        margin: 0,
        marginBottom:30,
        [breakpoints.down('xl')]: {
            fontSize: "48px"
        },
        [breakpoints.down('sm')]: {
            fontSize: "36px",
        }
    },
    sectionText: {
        fontFamily: "Cera Pro",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "18px",
        fontFamily: "DM Sans",
        lineHeight:"30px",
        color: colors.pageColor,
        maxWidth: "65%",
        alignSelf:"center",
        opacity: .8,
        margin: "0 auto 1.2rem",
        [breakpoints.down('xl')]: {
            fontSize: "18px"
        },
        [breakpoints.down('sm')]: {
            textAlign:"left",
            fontSize: "16px",
            maxWidth: "100%",
        },
    },
    networksContainer: {
        display: "flex",
        justifyContent: "center",
        [breakpoints.down('sm')]: {
            // flexDirection: "column",
            justifyContent: "center",
            marginTop: 0,
        },
    },

    dataContainer: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "6rem",
        [breakpoints.down(1690)]: {
            flexWrap: "wrap",
            marginTop: "3rem"
        },
        [breakpoints.down("sm")]: {
            justifyContent: "center",
        },
    }
}))



export default function GiftDappNetworks() {

    const classes = style();

    const networks = [
        {networkName: "Ethereum", logoName: "ETH-ICON"},
        {networkName: "Binance SmartChain", logoName:  "BNB-ICON"}, // logo name will be changed for this later when we get the icon for BSC
        {networkName: "Polygon", logoName: "MATIC-ICON"},
    ];

    const data = [
        {dataContent: "Easily exchange between your assets without giving control of your funds to anyone.", dataHead: "Extra layer of security", dataPicture: "security_2"},
        {dataContent: "We offer you the best transaction experience with the lowest fees available.", dataHead: "Low transaction fees", dataPicture: "fees"},
        {dataContent: "Your transactions are processed at lightning-fast speed.", dataHead: "Speed of light", dataPicture: "speed_2"},
    ];

    return(
        <section className = {classes.root}>
             <div className = {classes.networksContainer}>
                {networks.map((network, index) => <GiftDappNetworkItem key = {index} networkName = {network.networkName} logoName = {network.logoName}/>)}
            </div>
            <h2 className = {classes.heading}>
                Built across multiple platforms
            </h2>
            <p className = {classes.sectionText}>
            Built on Ethereum, Binance SmartChain, & Polygon to give you the freedom to experience more inclusivity and an extra layer of security when using our platforms.
            </p>
            <div className = {classes.dataContainer}>
                <Grid container spacing={2}>
                    {data && data.map((item, index) => {
                        const { dataHead, dataContent, dataPicture} = item;
                        return <Grid key={index} item xs={12} sm={6} md={4} lg={4} xl={4} >
                        <GiftDappNetworkCardItem key = {index} dataHead = {dataHead} dataContent = {dataContent} dataPicture = {dataPicture} />
                        </Grid>
                    })}
                </Grid>
            </div>
           
        </section>
    )
}