import { Drawer, makeStyles } from '@material-ui/core'
import React from 'react'
import GiftDappHeaderMenuItems from '../GiftDappHeaderMenuItems/GiftDappHeaderMenuItems';
const style = makeStyles(({colors, breakpoints}) => ({
    root : {
        
    },
    drawer : {
        backgroundColor: colors.background
    }
}))
export default function GiftDappHeaderSidebar ({ openDrawer, menuOptions = {} }) {
    const classes = style();
    return (
        <Drawer
            anchor={"right"}
            open={openDrawer}
            variant="persistent"
            classes={{
                paper : classes.drawer
            }}
        >
            <GiftDappHeaderMenuItems
                {...menuOptions}
                isSidebar
            />
        </Drawer>
    )
}
export { GiftDappHeaderSidebar };