import { makeStyles } from '@material-ui/styles';
import { ButtonBase } from '@material-ui/core';
import {landingRouteNames} from '../../../../utils'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import React from 'react'

const style = makeStyles(({colors, breakpoints} ) => ({
    root: {
        color: colors.pageColor,
        background: colors.pageBackground,
        textAlign: "left",
        position: "relative",
        transformStyle: "preserve-3d",
        paddingTop: "4rem",
        boxSizing: "border-box",
        [breakpoints.down('md')]: {
            paddingRight: "2.5rem",
            paddingLeft: "2.5rem",
        },
        [breakpoints.down('sm')]: {
            paddingRight: "0rem",
            paddingLeft: "0rem",
            paddingTop: "0rem",
        }
    },
    
    featuredContainer :{
        paddingLeft: "5rem",
        paddingRight: "5rem",
        paddingBottom: "0rem",
        margin: 30,
        display: "flex",
        justifyContent: "space-between",
        [breakpoints.down("md")]: {
            flexDirection: "column",
        },
        [breakpoints.down("sm")]: {
            paddingLeft: "0rem",
            paddingRight: "0rem",
            margin: 0,
        }
    },

    textSection: {
        width: "50%",
        marginTop: "5rem",
        [breakpoints.down("md")]: {
            width: "80%",
        },
        [breakpoints.down("sm")]: {
            width: "90%",
            marginTop: "0rem",
        },
        [breakpoints.down("xs")]: {
            width: "100%",
        }

    },

    ImgSection: {
        width: "50%",
        // height: "40rem",
        marginRight: "2rem",
        [breakpoints.down("md")]: {
            width: "80%",
            margin: "3rem auto 0",
        },
        [breakpoints.down("sm")]: {
            width: "100%",
        },
    },

    launchImg: {
        width: "100%",
        height: "100%"
    },
    journeyBtn: {
        boxShadow: "0px 1px 5px rgba(51, 51, 153, 0.1)",
        color: colors.buttonColor,
        fontSize: "16px",
        width: "212px",
        height: "56px",
        background: colors.landItmeBg,
        border: `1px solid ${colors.buttonColor}`,
        fonWeight: "700",
        padding: "1.5rem 5rem",
        borderRadius: "6px",
        outline: "none",
        [breakpoints.down('xl')]: {
            fontSize: "16px",
            padding: ".7rem 1.5rem",
            borderRadius: ".5rem",
        },
        "&:hover":{
            background: "linear-gradient(90deg,#2691c1 0%,#045ca6 42.71%,#055da6 60.42%,#238cbe 100%)",
            color:"white",
            transition:"0.3s all"
        }
    },
    projectName:{
        fontFamily: "Clash Display",
        fontStyle: "normal",
        fontWeight: 600,
        marginBottom:15,
        fontSize: "40px",
        lineHeight: "48px",
        color: colors.pageColor,
        [breakpoints.down("sm")]: {
            fontSize: "28px",
        },
    },
   
    projectDesc:{
        fontFamily: "DM Sans",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "16px",
        lineHeight: "24px",
        color: colors.pageColor,
        minWidth: "510px",
        marginBottom: "40px",
        [breakpoints.down('sm')]: {
            minWidth: "80%"
        }
    },
    smalllogo: {
        height: "35px",
        width: "35px",
        marginLeft: "10px",  
        marginTop: "2px",
        color: "inherit"
      },

}))
export default function GiftDappEveryone() {

    const classes = style();
   
    return(
        <section className = {classes.root}>
        <div className={ classes.featuredContainer}>
            <div className = {classes.ImgSection}>
                <img className = {classes.launchImg} src = "influencer.gif" alt = "launch" />
            </div>
            <div className = {classes.textSection}>
                <p className = {classes.projectName}>Earn Crypto</p>
                <p className = {classes.projectDesc}>
                Support new projects and Influencers by engaging with them…. As a supporting user you receive your token rewards instantly in a transparent way,get what you deserve.
                </p>
                <ButtonBase
                    onClick = {() => window.location.href = landingRouteNames().app}
                    className = {classes.journeyBtn}>
                    Launch DApp
                  <ArrowForwardIcon className={classes.smalllogo}/>
                </ButtonBase>
            </div>
        </div>
       
     </section>
   
    )
   
   }