export const landingTheme = {
    dark : {
        pageBackground : "#000C15",
        pageColor : "#FFFFFF",
        funColor: "#9BD0FD",
        LineColor: "#012645",
        linkcolor: "#4CAFFF",
        landItmeBg : "#15202B",
        buttonColor:"#008DFF",
        homeBorder : "#324D68",
        homeText : "#DCE5EF",
        homeLiner : "#4CAFFF",
        textBackground : "#fff",
    },
}