import React, { useState } from 'react';
import { makeStyles, Drawer, IconButton, List, ListItem, ListItemText, Grid, Typography, Divider } from "@material-ui/core";
import { Menu } from '@material-ui/icons'
import CloseIcon from '@material-ui/icons/Close';
import { Link } from 'react-router-dom';


const style = makeStyles(({ colors, breakpoints }) => ({
    root: {
        "& .MuiPaper-root": {
            backgroundColor: "#07071f",
            width: "100%",
            //maxWidth: "20rem",
            padding: "1rem",
            boxSizing: "border-box",

        },
    },
    navLink: {
        color: "grey",
        textDecoration: "none",
    },
    navDropDown: {
        color: "#fff",
        textDecoration: "none",
        fontSize: 'bold'
    },
    iconBtn: {
        color: "#fff",
        width: "2rem"
    },
    menuIcon: {
        width: "2rem",
        zIndex: 4,
    },
    smalllogo: {
        height: 16,
        width: 16,
        marginLeft: "2px",
        marginTop: "2px",
    },
    logoImage: {
        // width : 20,
        // height : 20,
        marginRight: ".5rem",
    },

    closeMenuButton: {
        color: "white"
    },

}))


export default function DrawerComponent() {
    const classes = style();
    const [openDrawer, setOpenDrawer] = useState(false);

    const [dappsDropDown, openDappsDropDown] = useState(false)
    const [companyDropDown, openCompanyDropDown] = useState(false)


    const companyDropDownItems = [
        { text: "About us", link: "https://www.rigelprotocol.com/about" },
        { text: "Career", link: "https://www.rigelprotocol.com/career" },
        { text: "Press resources", link: "https://www.rigelprotocol.com/press" },
    ]
    const dappsDropDownItems = [
        { text: "SmartSwap", link: "https://smartswap.rigelprotocol.com/" },
        { text: "Gift DApp", link: "https://gift.rigelprotocol.com/" },
        { text: "Auto Period", link: "https://smartswap.rigelprotocol.com/#/auto-period" },
        { text: "SmartBid", link: "https://smartswap.rigelprotocol.com/#/smartbid" },
        { text: "Farms", link: "https://smartswap.rigelprotocol.com/#/farming-v2" },
        { text: "Launchpad", link: "https://launchpad.rigelprotocol.com" },
    ]

    return (
        <>
            <Drawer
                className={classes.root}
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
            >
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Grid container alignItems="center" justify="space-between">
                            <Grid item>
                                <div className={classes.logoContainer}>
                                    <img
                                        src="/landlogo2.svg"
                                        className={classes.logoImage}
                                        alt="LaunchPad Rigel Protocol"
                                    />
                                </div>
                            </Grid>
                            <Grid item>
                                <IconButton onClick={() => setOpenDrawer(!openDrawer)} className={classes.closeMenuButton}>
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>


                <List padding={4}>

                    <ListItem >
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Grid container alignItems="center" justify="space-between" onClick={() => openCompanyDropDown(!companyDropDown)}>
                                    <Grid item>
                                        <Typography className={classes.navLink} >Company</Typography>
                                    </Grid>
                                    <Grid item>
                                        <img
                                            src="/header/arrow-down.svg"
                                            className={classes.smalllogo}
                                            alt={" LaunchPad arrow down"}
                                        />
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>

                    </ListItem>

                    {companyDropDown &&

                        companyDropDownItems.map((item, index) => (
                            <ListItem key={index} >
                                <ListItemText>
                                    <a target='blank' href={item.link} className={classes.navDropDown}>{item.text}</a>
                                </ListItemText>
                            </ListItem>
                        ))}


                    <Divider style={{ background: 'grey', marginTop: "5px" }} />

                    <ListItem >
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Grid container alignItems="center" justify="space-between" onClick={() => openDappsDropDown(!dappsDropDown)}>
                                    <Grid item>
                                        <Typography className={classes.navLink} >DApps</Typography>
                                    </Grid>
                                    <Grid item>
                                        <img
                                            src="/header/arrow-down.svg"
                                            className={classes.smalllogo}
                                            alt={" LaunchPad arrow down"}
                                        />
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>

                    </ListItem>

                    {dappsDropDown &&

                        dappsDropDownItems.map((item, index) => (
                            <ListItem key={index} >
                                <ListItemText>
                                    <a target='blank' href={item.link} className={classes.navDropDown}>{item.text}</a>
                                </ListItemText>
                            </ListItem>
                        ))}


                    <Divider style={{ background: 'grey', marginTop: "5px" }} />


                    <ListItem >
                        <ListItemText>
                            <Link to="/contact" className={classes.navLink}>  Events</Link>
                        </ListItemText>
                    </ListItem>
                    <ListItem >
                        <ListItemText>
                            <Link to="/contact" className={classes.navLink}>   Blogs
                                <img
                                    src="/header/export.svg"
                                    className={classes.smalllogo}
                                    alt={" LaunchPad arrow down"}
                                /></Link>
                        </ListItemText>
                    </ListItem>
                </List>
            </Drawer>
            <IconButton onClick={() => setOpenDrawer(!openDrawer)} className={classes.iconBtn}>
                <Menu className={classes.menuIcon} />
            </IconButton>
        </>
    )
}
export { DrawerComponent };