import { giftDappRouteNames } from "./routeNames";
import { SupportedChainId } from "./constants";
import axios from "axios";

export const telegram_bot_base_url = "https://api.telegram.org/bot";

export const mergeComponentClasses = (classObject, overideObject) => {
    if (classObject)
        for (const name in classObject) {
            if (Object.hasOwnProperty.call(classObject, name)) {
                const _class = classObject[name];
                if (overideObject && overideObject[name])
                    classObject[name] = overideObject[name] + " " + _class;
            }
        }
    return classObject;
};
export const shortAddress = (address) => {
    if (!address) return "...";
    return (
        String(address).substr(0, 4) +
        "..." +
        String(address).substr(address.length - 4, 4)
    );
};
export const onEnterPress = (action) => (event) => {
    if (event.key === "Enter") {
        event.preventDefault();
        event.stopPropagation();
        if (action) action(event);
    }
};
export const copyToClipboard = (text, keepTabs) => {
    let input;
    if (keepTabs) {
        input = document.createElement("textarea");
        input.innerHMTL = text;
    } else {
        input = document.createElement("input");
        input.setAttribute("value", text);
    }
    document.body.appendChild(input);
    input.select();
    var result = document.execCommand("copy");
    navigator.clipboard.writeText(text);
    document.body.removeChild(input);
    return result;
};
export const customSuperSetState = (context) => {
    return function (state, callback) {
        if (state instanceof Function) {
            if (!context.state) throw "The component has no state";
            else {
                var newStateObject = state(context.state);
                context.setState(newStateObject);
            }
        } else {
            context.setState(state);
            if (callback) callback();
        }
    };
};
export const isFunction = function (obj) {
    return !!(obj && obj.constructor && obj.call && obj.apply);
};
export const inputDecimalValidator = (value) =>
    /^(^$|[0-9]+|[0-9]+\.|[0-9]+\.[0-9]+)$/.test(value);
export const redditInputValidator = (value) =>
    /^(https?:\/\/)(www.)?reddit.com\/(r|user)\/[\w-]+/.test(value);
export const youtubeInputValidator = (value) =>
    /^(https?:\/\/)(www.)?youtube.com\/(channel)\/[\w-]+$/.test(value) ||
    /^UC/.test(value);
export const twitterInputValidator = (value) =>
    /^(https?:\/\/)(www.)?twitter.com\/(\w){1,15}$/.test(value) ||
    /^@\w+$/.test(value);
export const discordInputValidator = (value) =>
    /^(https?:\/\/)(www.)?(discord.com)\/channels\/[0-9]{18}(\/)?([0-9]{18})?$/.test(
        value
    );
export const discordInviteInputValidator = (value) =>
    /^(?:https?:\/\/)?discord(?:(?:app)?\.com\/invite|\.gg)\/?[a-zA-Z0-9]+\/?$/.test(
        value
    );
export const telegramInputValidator = (value) =>
    /^((?:https?:\/\/)?(t(elegram)?\.me)\/|@)([a-z0-9\_]{5,32})$/.test(value);

export const extractTelegram_group_channel_Name = (nameOrLink) => {
    if (
        !/^((?:https?:\/\/)?(t(elegram)?\.me)\/|@)([a-z0-9\_]{5,32})$/.test(
            nameOrLink
        )
    )
        return;
    if (nameOrLink.startsWith("@")) return nameOrLink.slice(1);
    return nameOrLink.split("/").slice(-1)[0];
};

export const getTelegram_group_channel_Member = async (
    channel_group_name,
    userID
) => {
    try {
        const res = await axios.get(
            `${telegram_bot_base_url}${getTelegramBotToken()}/getChatMember?chat_id=@${channel_group_name}&user_id=${userID}`
        );
        if (res?.data?.result?.status)
            return { ok: res.data.ok, status: res.data.result.status };
        else {
            return { ok: false, description: "something went wrong!" };
        }
    } catch (err) {
        return {
            ok: err.response.data.ok,
            description: err.response.data.description,
        };
    }
};

export const getReditAuthCredentials = (key) => {
    const site = window.location.href.split("/")[2];
    let version;
    switch (site) {
        case "giftdapp.rigelprotocol.com":
            version = "GIFTDAPP";
            break;
        case "gifttest.rigelprotocol.com":
            version = "TEST";
            break;
        default:
            version = "LOCAL";
            break;
    }
    return process.env[`REACT_APP_REDDIT_${version}_${key}`];
};

export const getDiscordRedirectURI = () => {
    const site = window.location.href.split("/")[2];
    let version;
    switch (site) {
        case "gift.rigelprotocol.com":
            version = "GIFT";
            break;
        case "giftdapp.rigelprotocol.com":
            version = "GIFTDAPP";
            break;
        case "gifttest.rigelprotocol.com":
            version = "TEST";
            break;
        default:
            version = "LOCAL";
            break;
    }
    return process.env[`REACT_APP_DISCORD_${version}_REDIRECT_URI`];
};

export const getTwitterRedirectUri = () => {
    const site = window.location.href.split("/")[2];
    let version;
    switch (site) {
        case "gift.rigelprotocol.com":
            version = "GIFT";
            break;
        case "giftdapp.rigelprotocol.com":
            version = "GIFTDAPP";
            break;
        case "gifttest.rigelprotocol.com":
            version = "TEST";
            break;
        default:
            version = "LOCAL";
            break;
    }

    const callback_url =
        process.env[`REACT_APP_TWITTER_CALLBACK_URL_${version}`];
    return callback_url;
};

export const getTelegramBotToken = () => {
    const site = window.location.href.split("/")[2];
    let version;
    switch (site) {
        case "giftdapp.rigelprotocol.com":
            version = "GIFTDAPP";
            break;
        case "gifttest.rigelprotocol.com":
            version = "TEST";
            break;
        case "deploy-preview-167--wonderful-hodgkin-8c98c2.netlify.app":
            version = "PREVIEW";
            break;
        default:
            version = "LOCAL";
            break;
    }
    return process.env[`REACT_APP_TELEGRAM_${version}_BOT_TOKEN`];
};

export const getTelegramBotId = () => {
    const site = window.location.href.split("/")[2];
    let version;
    switch (site) {
        case "giftdapp.rigelprotocol.com":
            version = "GIFTDAPP";
            break;
        case "gifttest.rigelprotocol.com":
            version = "TEST";
            break;
        case "deploy-preview-167--wonderful-hodgkin-8c98c2.netlify.app":
            version = "PREVIEW";
            break;
        default:
            version = "LOCAL";
            break;
    }
    return process.env[`REACT_APP_TELEGRAM_${version}_BOT_ID`];
};

export const getTelegramBotUsername = () => {
    const site = window.location.href.split("/")[2];
    let version;
    switch (site) {
        case "giftdapp.rigelprotocol.com":
            version = "GIFTDAPP";
            break;
        case "gifttest.rigelprotocol.com":
            version = "TEST";
            break;
        case "deploy-preview-167--wonderful-hodgkin-8c98c2.netlify.app":
            version = "PREVIEW";
            break;
        default:
            version = "LOCAL";
            break;
    }
    return process.env[`REACT_APP_TELEGRAM_${version}_BOT_USERNAME`];
};

export const randomStringGenerator = () =>
    (Math.random() + 1).toString(36).substring(2);
export const generateEventLink = (baseRoute, networkId, eventId) =>
    (baseRoute ? baseRoute : "") +
    giftDappRouteNames().eventView_ +
    networkId +
    "/" +
    eventId;

const ETHERSCAN_PREFIXES = {
    [SupportedChainId.ROPSTEN]: "ropsten.",
    [SupportedChainId.RINKEBY]: "rinkeby.",
    [SupportedChainId.GOERLI]: "goerli.",
    [SupportedChainId.KOVAN]: "kovan.",
    [SupportedChainId.POLYGON]: "polygon.",
    [SupportedChainId.BINANCE]: "binance.",
    [SupportedChainId.BINANCETEST]: "binance-test.",
    [SupportedChainId.POLYGONTEST]: "polygon-test.",
};

export const ExplorerDataType = {
    TRANSACTION: "transaction",
    TOKEN: "token",
    ADDRESS: "address",
    BLOCK: "block",
};

export function getExplorerLink(chainId, data, type) {
    if (chainId === SupportedChainId.POLYGON) {
        switch (type) {
            case ExplorerDataType.TRANSACTION:
                return `https://polygonscan.com/tx/${data}`;
            case ExplorerDataType.ADDRESS:
            case ExplorerDataType.TOKEN:
                return `https://polygonscan.com/address/${data}`;
            case ExplorerDataType.BLOCK:
                return `https://polygonscan.com/block/${data}`;
            default:
                return `https://polygonscan.com`;
        }
    }

    if (chainId === SupportedChainId.POLYGONTEST) {
        switch (type) {
            case ExplorerDataType.TRANSACTION:
                return `https://mumbai.polygonscan.com/tx/${data}`;
            case ExplorerDataType.ADDRESS:
            case ExplorerDataType.TOKEN:
                return `https://mumbai.polygonscan.com/address/${data}`;
            case ExplorerDataType.BLOCK:
                return `https://mumbai.polygonscan.com/block/${data}`;
            default:
                return `https://mumbai.polygonscan.com`;
        }
    }

    if (chainId === SupportedChainId.BINANCE) {
        switch (type) {
            case ExplorerDataType.TRANSACTION:
                return `https://www.bscscan.com/tx/${data}`;
            case ExplorerDataType.ADDRESS:
            case ExplorerDataType.TOKEN:
                return `https://www.bscscan.com/address/${data}`;
            case ExplorerDataType.BLOCK:
                return `https://www.bscscan.com/block/${data}`;
            default:
                return `https://www.bscscan.com`;
        }
    }

    if (chainId === SupportedChainId.BINANCETEST) {
        switch (type) {
            case ExplorerDataType.TRANSACTION:
                return `https://testnet.bscscan.com/tx/${data}`;
            case ExplorerDataType.ADDRESS:
            case ExplorerDataType.TOKEN:
                return `https://testnet.bscscan.com/address/${data}`;
            case ExplorerDataType.BLOCK:
                return `https://testnet.bscscan.com/block/${data}`;
            default:
                return `https://testnet.bscscan.com`;
        }
    }

    const prefix = `https://${ETHERSCAN_PREFIXES[chainId] ?? ""}etherscan.io`;

    switch (type) {
        case ExplorerDataType.TRANSACTION:
            return `${prefix}/tx/${data}`;

        case ExplorerDataType.TOKEN:
            return `${prefix}/token/${data}`;

        case ExplorerDataType.BLOCK:
            if (
                chainId === SupportedChainId.GOERLI ||
                chainId === SupportedChainId.ROPSTEN ||
                chainId === SupportedChainId.KOVAN ||
                chainId === SupportedChainId.RINKEBY
            ) {
                return `${prefix}/tx/${data}`;
            }
            return `${prefix}/block/${data}`;

        case ExplorerDataType.ADDRESS:
            return `${prefix}/address/${data}`;
        default:
            return `${prefix}`;
    }
}
