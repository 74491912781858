import { makeStyles } from '@material-ui/core'
import React from 'react'
import { CustomDialog } from '../../../../../includes';
const style = makeStyles(({colors, breakpoints}) => ({
    root : {
        
    },
    dialogPaper : {
        background : colors.background,
        border : "solid 1px " + colors.accentSecondary,
        color : colors.text,
        overflow : "visible",
    },
    dialogClose : {
        background : colors.text,
        top : -40,
        padding : 5,
        "&:hover" : {
            background : colors.accent,
            color : colors.textSecondary
        }
    },
    dialogbox : {
        padding : "20px 40px",
        display : "flex",
        flexDirection : "column",
    },
    dialogHeader : {
        fontWeight : "bold",
        textAlign : "center",
        marginBottom : 20,
    },
    googleBtnContainer : {
        display : "flex",
        justifyContent : "center",
        marginTop : 50,
    },
    googleBtn : {
        height : 50,
        cursor : "pointer"
    },
}))
export default function GoogleLoginNotification ({
    showDialog, setShowDialog, onClick
}) {
    const classes = style();
    return (
        <div className={classes.root}>
            <CustomDialog
                within
                open={showDialog}
                close={() => setShowDialog(false)}
                classes={{
                    dialogPaper : classes.dialogPaper,
                    _close : classes.dialogClose,
                }}
            >
                <div className={classes.dialogbox}>
                    <div className={classes.dialogHeader}>
                        Youtube Login
                    </div>
                    You will need to login into your google account and give permition to youtube data,
                    for channel Id verification.
                    <div className={classes.googleBtnContainer}>
                        <img 
                            onClick={onClick} 
                            className={classes.googleBtn} 
                            src="/assets/google_btn.png" title="google login"/>
                    </div>
                </div>
            </CustomDialog>
        </div>
    )
}