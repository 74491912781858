import React, { useEffect, useState, useCallback } from 'react'
import { Divider, Grid, makeStyles } from '@material-ui/core'
import { useSelector } from 'react-redux';
import { CheckCircleOutline } from '@material-ui/icons';
import { breadCrumbActions, breadCrumbStrings, CustomDialog } from '../../../includes';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook } from '@fortawesome/free-solid-svg-icons';
import { appValues, history } from '../../../helpers';
import { getAddressBook, giftDappRouteNames, NETWORKS, STORAGE_VARIABLE } from '../../../utils';
import { Helmet } from 'react-helmet';
import createBrowserHistory from 'history/createBrowserHistory';
import { styled } from '@mui/system';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import { walletActions, walletStrings, WALLET_REDUCER_NAME } from '../../structures';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled';
import useGAEventsTracker from './../../../hooks/useGAEventsTracker';
import { GiftDappNetworks } from '../../structures/GiftDappHeader/GiftDappConnection/GiftDappNetworks';
import walletUtil from '../../../utils/web3/walletUtil';
import walletConnection from '../../../utils/web3/walletConnection';

const Tab = styled(TabUnstyled)`
  font-family: IBM Plex Sans, sans-serif;
  color: #7599BD;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: bold;
  background-color: transparent;
  width: 100%;
  padding: 12px 16px;
  margin: 6px 6px;
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: #4CAFFF;
  }

  &:focus {
    color: #7599BD;
    border-radius: 3px;
    outline: 2px solid #4CAFFF;
    outline-offset: 2px;
  }

  &.${tabUnstyledClasses.selected} {
    background-color: #4CAFFF;
    color: white;
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabsList = styled(TabsListUnstyled)`
    min-width: 160px;
    background-color: transparent;
    border-radius: 6px;
    border: 1px solid #4CAFFF;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: space-between;
`;

const style = makeStyles(({breakpoints, colors}) => ({
    root : {
        display : "flex",
        flexDirection : "column",
        alignItems : "center",
        borderRadius : 10,
        fontFamily : "revert",
        [breakpoints.up("sm")] : {
            padding : 20
        },
        maxWidth : 1000,
        [breakpoints.up("lg")] : {
        }
    },
    header : {
        fontSize : "56px",
        fontWeight : "bold",
        fontFamily: "Clash Display",
        display : 'flex',
        justifyContent : 'center',
        marginBottom : 10,
        marginTop : 50,
        [breakpoints.down("xs")] : {
            justifyContent : "flex-start"
        }
    },
    breaker : {
        marginTop : 50,
        [breakpoints.down("xs")] : {
            marginTop : 50,
        }
    },
    description : {
        display : "flex",
        fontSize : 18,
        flexDirection : "column",
        textAlign : "center",
        alignItems : "center",
        justifyContent : "center",
        lineHeight : 2,
        color : colors.text, // new design color "#DCE5EF",
        // padding : "20px 50px",
        [breakpoints.up("sm")] : {
            padding : "20px 50px",
        },
        [breakpoints.up("md")] : {
            padding : "20px 100px",
        },
        [breakpoints.up("lg")] : {
            padding : "20px 120px",
        },
        [breakpoints.down("xs")] : {
            justifyContent : "flex-start",
            textAlign : "start"
        },
    },
    box : {
        position : "relative",
        fontSize : "smaller",
        border : `solid 0.5px ${colors.homeBorder}`,
        color : colors.homeText,
        cursor:"pointer",
        fontSize : 14,
        borderRadius : 10,
        [breakpoints.up("sm")] : {
        },
        "&:hover": {
        transform:"translate(0px, -15px)",
        transition:"0.3s",
        border : `dashed 0.5px ${colors.buttonColor}`,
        }
    },
    box2 : {
        "&:hover": {
            transform:"rotate(3deg)",
            transition:"0.3s"
        }
    },
    box3 : {
        "&:hover": {
            transform:"rotate(-3deg)",
            transition:"0.3s"
        }
    },
    itemBody : {
        minHeight : 380,
        padding : 20,
        [breakpoints.down("sm")] : {
            minHeight : 400,

        }
    },
    divider : {
        background : colors.homeBorder,
        marginTop : 20,
        borderTop : `solid .1 gray !important`,
        // boxShadow: "0px 0px 10px"
    },
    _header : {
        fontSize : 16,
        fontWeight : "bold",
        marginBottom : 20,
        fontFamily:"Clash Display"
    },
    homeText : {

    },
    imageContainer : {
        padding : 20,
        display : "flex",
        justifyContent : "center",
        alignItems : "center",
        height : 200,
    },
    image : {
        height : 200,
        width : 250,
    },
    icon : {
        color : colors.homeLiner,
        minWidth : 40,
        [breakpoints.down("sm")] : {
            // display : "none",
        }
    },
    info : {
        lineHeight : 2,
        marginBottom : 10,
    },
    _itemContainer : {
        display : "flex",
        alignItems : "center",
        marginBottom : 10,
    },
    btnContainer : {
        position : "absolute",
        bottom : 0,
        left : 0,
        // width : "calc(100% - 40px)",
        width : "100%",
        padding :20,
    },
    button : {
        border : `solid 1px ${colors.buttonColor}`,
        background:colors.landItmeBg,
        boxShadow: "0px 0.888672px 6.2207px -1.77734px rgba(24, 39, 75, 0.06), 0px 1.77734px 1.77734px rgba(24, 39, 75, 0.06)",
        borderRadius: 6,
        color:colors.buttonColor,
        padding : 10,
        display : "flex",
        justifyContent : "center",
        textAlign : "center",
        borderRadius : 5,
        cursor : "pointer",
        "&:hover" : {
            background:colors.buttonColor,
            color:colors.textBackground,
            transition:0.3
        }
    },
    footer : {
        display : "flex",
        border : `solid 1px ${colors.homeLiner}`,
        borderRadius : 10,
        padding : "10px 20px",
        cursor : "pointer",
    },
    fotterContainer : {
        marginTop : 50,
        [breakpoints.up("sm")] : {
            padding : 20,
        },
        display : "flex",
        justifyContent : "center",
        alignItems : "center",
        color : colors.homeLiner,
    },
    launchWrapper : {
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        margin:"30px 0"
    },
    launchButton : {
        background: "linear-gradient(90deg,#2691c1 0%,#045ca6 42.71%,#055da6 60.42%,#238cbe 100%)",
        boxShadow: "0px 1px 5px rgba(51, 51, 153, 0.1)",
        border: "0",
        color: "white",
        textAlign: "center",
        fontSize: 16,
        fontFamily: "DM Sans",
        cursor: "pointer",
        width: 262,
        height: 56,
        borderRadius:6,
    },
    dialogPaper : {
        background : colors.background,
        border : "solid 1px " + colors.accentSecondary,
        color : colors.colorstext,
        overflow : "visible",
    },
    dialogClose : {
        background : colors.text,
        top : -40,
        padding : 5,
        "&:hover" : {
            background : colors.accent,
            color : colors.textSecondary
        }
    },
}))
function GiftDappRewardHome({rewardAction}) {
    const classes = style(),
    dispatch = useDispatch(),
    [state, _setState] = useState({
        openDialog : false,
    }),
    { openDialog } = state,
    setState = _state => _setState(state=>({...state, ..._state})),
    closeDialog = () => setState({openDialog : false}),
    walletReducer = useSelector((state)=> state[WALLET_REDUCER_NAME]),
    {
        [walletStrings.address] : address,
        [walletStrings.networkType] : networkType,
        [walletStrings.web3] : web3,
        [walletStrings.networkWallet] : networkWallet,
        [walletStrings.contractAddresses] : contractAddresses,
    } = walletReducer,
    onNetworkSelect = useCallback(async(networkId)=>{
        try{
            const _id = walletUtil.getTruffleNetworkName(networkId);
            if(!NETWORKS[_id]) throw new Error("Network metadata not configured");
            else{
                const { web3 } = await walletConnection(
                    contractAddresses, 
                    networkWallet || "metamask", 
                    null, true
                );
                const provider = web3._provider;
                await walletUtil.switchNetwork(provider, NETWORKS[_id].chainId, NETWORKS[_id]);
                await walletConnection(
                    contractAddresses, 
                    networkWallet || "metamask", 
                    null, false
                );
            }
        }catch(e){
            console.error(e)
        }
    },[JSON.stringify(contractAddresses)]);
    useEffect(() => {
        breadCrumbActions.payload({
            [breadCrumbStrings.visible] : false,
        })(dispatch);
    }, []);
    const isProduction = process.env.NODE_ENV === "production", 
    pageData = [
        {
            header : "For Influencer",
            description : <div>Become a <i><strong>reputable</strong></i> influencer with the opportunity to gift crypto to your followers and subscribers</div>,
            items : [
                "Reward your followers and supporters",
                "Become more reputable",
                "Generate more clicks and popularity"
            ],
            image : "/assets/home/influencer.png",
            clickAction : () => rewardAction(false)
        },{
            header : "For Projects",
            description : <div>
                Reward your users in a funny way, while also saving<i><strong>time</strong></i> and <i><strong>money</strong></i>
            </div>,
            items : [
                "Increase hype, support and userbase",
                "Land major investors by becoming reputable",
                "Generate more clicks and popularity"
            ],
            image : "/assets/home/project.png",
            clickAction : () => rewardAction(true)
        },{
            header : "For Everyone Else",
            description : <div>
                Still curious about what you can accomplish? We let you do it all:
            </div>,
            items : [
                "Claim instant reward from your favourite influencers or projects",
                "Refer your friends for more rewards",
            ],
            image : "/assets/home/everyone.png",
            clickAction : () => history.push(giftDappRouteNames().events)
        }
    ]
    const history = createBrowserHistory({forceRefresh:true});
    const switchVersion = version => {
        if(isProduction)
           window.location.href = version;
        else history.push(version);
    };
    const GAEventsTracker = useGAEventsTracker(),
    v1Link = isProduction ? appValues.v1Url : '/',
    v2Link = isProduction ? appValues.v2Url : '/v2';

    return <div className={classes.root}>
        <Grid container>
            <TabsUnstyled defaultValue={1} style={{marginLeft: 'auto'}}>
              <TabsList>
                <Tab onClick={() => { switchVersion(v1Link); GAEventsTracker("switch_to_v1", "reward page"); }}>V1</Tab>
                <Tab onClick={() => switchVersion(v2Link)}>V2</Tab>
              </TabsList>
            </TabsUnstyled>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <>
                    <h1 className={classes.header}>
                    Gift your users in fun ways
                    </h1>
                    <div className={classes.description}>
                    Reward your users or followers in a fun way by having them win tokens through games
                    </div>
                    {!address && <div className={classes.launchWrapper}>
                       <button onClick={() => setState({openDialog : true})} className={classes.launchButton}>
                        Launch DApp
                    </button> 
                    </div>}
                    
                </>
            </Grid>
        </Grid>
        <Helmet>
        <title>Rigel protocol Gift Application and Gift Rewards</title>
            <meta name="description" content="Create an interesting experience for your followers and users to get rewards.
            You have the opportunity to create a reward as an influencer for your followers or as
            a project for your users. It is a great way to reward your followers and users for their loyalty!!!" />
            <meta name="Keywords" content="Rewards, Gift Rewards, For Influencer, For Projects, For Everyone Else " />
                <link rel="Rigel Protocol" href="http://rigelprotocol.com" />
        </Helmet>
        <div style={{marginTop : 20}}/>
        <Grid container spacing={4}>
            {pageData.map((item, index) => {
                const {header, description, items, image, clickAction} = item;
                return <Grid key={index} item xs={12} sm={6} md={4} lg={4} xl={4} >
                    <div className={ index ===1 ?`${classes.box}` : index ===2 ? `${classes.box} ${classes.box2}` : `${classes.box} ${classes.box3}` } >
                        <p className={classes.imageContainer}>
                            <img className={classes.image} src={image} title={header}/>
                        </p>
                        <Divider className={classes.divider}/>
                        <div className={classes.itemBody}>
                            <h1 className={classes._header}>
                                {header}
                            </h1>
                            <div className={classes.info}>
                                {description}
                            </div>
                            <div className={classes.itemContainer}>
                                {items.map((_item, index) =>{
                                    return <div key={index} className={classes._itemContainer}>
                                        <CheckCircleOutline className={classes.icon}/>
                                        <div className={classes._item}>{_item}</div>
                                    </div>
                                })}
                            </div>
                            <div className={classes.btnContainer}>
                                <div className={classes.button} onClick={() => { clickAction(); GAEventsTracker(`get_started_${header}`, "reward page"); }}>Get Started</div>
                            </div>
                        </div>
                    </div>
                </Grid>
            })}
        </Grid>
        <div className={classes.fotterContainer}>
            <div className={classes.footer} onClick={() => { window.open("https://prodocs.rigelprotocol.com", "blank"); GAEventsTracker("click_on_documentation", "reward page"); }}>
                <FontAwesomeIcon size="lg" className={classes.icon} icon={faBook}/>
                See Documentation
            </div>
        </div>
        <CustomDialog
            within
            classes={{
                dialogPaper : classes.dialogPaper,
                _close : classes.dialogClose,
            }}
            open={openDialog}
            close={closeDialog}
        >
            <GiftDappNetworks
                network={networkType}
                onSelect={onNetworkSelect}
            />
        </CustomDialog>
    </div>



}
GiftDappRewardHome.propTypes = {

}

export default GiftDappRewardHome;
export {GiftDappRewardHome};
