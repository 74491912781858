import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles, withWidth } from '@material-ui/core'
import { gql, useLazyQuery } from '@apollo/client';
import { walletStrings, WALLET_KEYS, WALLET_REDUCER_NAME } from '../../../../structures';
import { getContractInstance, giftContractActions, tokenContractActions, ZERO_ADDRESS } from '../../../../../utils';
import { EVENT_BREAKPOINTS, NETWORK_TYPE } from '../../..';
import GiftDappEventListBoxGrid from './GiftDappEventListBoxGrid/GiftDappEventListBoxGrid';
import GiftDappEventListitem from './GiftDappEventListitem/GiftDappEventListitem';
import { onEditEvent } from '../../../..';
import { CustomDialog } from '../../../../../includes';
import GiftDappEventDescription from './GiftDappEventDescription/GiftDappEventDescription';
import walletUtil from '../../../../../utils/web3/walletUtil';
const style = makeStyles(({colors}) => ({
    box : {
        background : "linear-gradient(95deg, " + 
            colors.accentSecondary + ", " + 
            colors.secondary + ")",
        padding : 25,
    },
    cell : {
        border : "none",
        color : colors.text,
        padding : 10,
    },
    btnContainer : {
        display : "flex",
        justifyContent : "flex-end",
    },
    title : {
        fontStyle : "italic",
        color : colors.textSecondary,
        marginRight : 20,
    },
    logo : {
        height : 50,
        width : 50,
        borderRadius : "100%",
        background : colors.secondary,
        display : "flex",
        alignItems: "center",
        justifyContent : "center",
    },
    dialogPaper : {
        background : colors.background,
        border : "solid 1px " + colors.accentSecondary,
        color : colors.text,
        overflow : "visible",
    },
    dialogClose : {
        background : colors.text,
        top : -40,
        padding : 5,
        "&:hover" : {
            background : colors.accent,
            color : colors.textSecondary
        }
    },
})),
GET_TOKEN_PRICE = gql`
    query getCoinPricesBySympbols ($symbols : [String], $filter : CoinPriceInput) {
        prices : getCoinPricesBySympbols(symbols : $symbols, filter :$filter) {
            name symbol price
        }
    }
`;
function _GiftEventListBoxContainer({width, onClaim, rewardEvent}) {
    const classes = style(),
    dispatch = useDispatch(),
    {_id : eventId, projectIndex, isProject, profileId, name, image, networkType, participants, textList} = rewardEvent || {},
    network = walletUtil.printNetworkName(networkType),
    [state, _setState] = useState({symbols : [], blockReward : null, openDialog : false}),
    {symbols, blockReward, blockTokens: tokenValues, blockTickers : spinFields, openDialog} = state,
    setState = _state => _setState(state=>({...state, ..._state})),
    walletReducer = useSelector(state=>state[WALLET_REDUCER_NAME]),
    {
        [walletStrings.web3] : web3,
        [walletStrings.address] : address,
        [walletStrings.networkType] : _networkType,
    } = walletReducer,
    [getPrices, {data : tokenPriceData}] = useLazyQuery(GET_TOKEN_PRICE), 
    {prices} = tokenPriceData|| {},
    closeDialog = () => setState({openDialog : false}),
    openDescription = () => setState({openDialog : true}),
    getTokenSpins = (spinFields) => {
        const values = [];
        if(spinFields) for (let i = 0; i < spinFields.length; i++) {
            const {token : address} = spinFields[i];
            if(address !== ZERO_ADDRESS){
                values.push(spinFields[i]);
            }
        }
        return values;
    };
    useEffect(() => {
        (async () => {
            if(projectIndex){
                let [blockReward, blockTickers, blockTokens] = 
                    await Promise.all([
                        giftContractActions(isProject).getRewardById(parseInt(projectIndex)),
                        giftContractActions(isProject).getProjectTickers(parseInt(projectIndex, textList)),
                        giftContractActions(isProject).getProjectTokens(parseInt(projectIndex))

                    ]);
                const newBlockTickers = [];
                if(blockTickers) for (let i = 0; i < blockTickers.length; i++) {
                    const {
                        claims, cumulitiveSum, initialClaimTotal, 
                        rewardAmount, text, token, weight
                    } = blockTickers[i];
                    const ticker = {claims, cumulitiveSum, initialClaimTotal, rewardAmount, text, token, weight};
                    if(token !== ZERO_ADDRESS){
                        const value = await tokenContractActions(await getContractInstance(web3, null, token)).reducerNumber(rewardAmount);
                        ticker.value = value;
                    }          
                    newBlockTickers.push(ticker)         
                }
                let hasExpired = false;
                if(blockReward){
                    const {activePeriodInDays, projectStartTime} = blockReward,
                        startDateDiff = Math.abs(Date.now() / 1000 - projectStartTime),
                        projectSpanInDays = startDateDiff / 3600 / 24;
                        if(projectSpanInDays > activePeriodInDays)
                            hasExpired = true;
                }
                setState({blockReward : {...blockReward, hasExpired}, blockTickers : newBlockTickers, blockTokens})
            }
        })()
    }, [projectIndex, isProject]);
    useEffect(() => {
        (async () =>{
            const symbols = [];
            if(tokenValues) for (let i = 0; i < tokenValues.length; i++) {
                const {token : address, amount} = tokenValues[i];
                if(web3){
                    //get symbols to search the dollar equivalent in market cap
                    try{
                        const contractInstance = await getContractInstance(web3, null, address);
                        const symbol = await tokenContractActions(contractInstance).symbol();
                        symbols.push(symbol);
                    }catch(error){
                        // console.trace(error);
                    }
                }
            }
            setState({symbols})            
        })();
    }, [JSON.stringify(tokenValues)]);
    useEffect(() => {
        if(symbols && symbols.length > 0){
            getPrices({
                variables : {
                    symbols, 
                    filter : {}
                }
            })
        }
    }, [JSON.stringify(symbols)]);
    const descTokenFields = spinFields && getTokenSpins(spinFields).sort((a, b) => parseInt(a.value) - parseInt(b.value));
    const _tokenPrice = prices?.[0] ? parseFloat(prices[0]?.price) : 0;
    const minReward = descTokenFields ? descTokenFields[0]?.value : 0;
    const maxReward = descTokenFields ? descTokenFields[descTokenFields.length - 1]?.value : 0;
    const minDollar = Number(minReward * _tokenPrice).toLocaleString();
    const maxDollar = Number(maxReward * _tokenPrice).toLocaleString();
    const rewardRange = `$${minDollar === 0 && maxDollar > 0 ? 0.1 : minDollar || 0} to $${maxDollar || 0}`;
    const reward = {...rewardEvent, spinFields, tokenValues, blockReward},
    {projOwner} = blockReward || {},
    isMyReward = address && projOwner && (address === projOwner);
    return <>
        {EVENT_BREAKPOINTS.indexOf(width) === -1 ? <GiftDappEventListitem
            name={name}
            rewardRange={rewardRange}
            network={network}
            onClaim={onClaim(reward)}
            image={image}
            profileId={profileId}
            eventId={eventId}
            expired={blockReward?.hasExpired}
            isMyReward={isMyReward}
            participants={participants}
            onEdit={() => onEditEvent(_networkType, web3, dispatch, {...rewardEvent, ...blockReward, spinFields, tokenValues})}
            onDescription={openDescription}
        /> : <GiftDappEventListBoxGrid
                name={name}
                rewardRange={rewardRange}
                network={network}
                onClaim={onClaim(reward)}
                image={image}
                profileId={profileId}
                eventId={eventId}
                expired={blockReward?.hasExpired}
                isMyReward={isMyReward}
                onEdit={() => onEditEvent(_networkType, web3, dispatch, {...rewardEvent, ...blockReward, spinFields, tokenValues})}
                onDescription={openDescription}
            />}
            <CustomDialog
                within
                open={openDialog}
                close={closeDialog}
                classes={{
                    dialogPaper : classes.dialogPaper,
                    _close : classes.dialogClose,
                }}
            >
                <GiftDappEventDescription
                    data={rewardEvent}

                />
            </CustomDialog>
        </>
    }
_GiftEventListBoxContainer.propTypes = {
    
}
export const GiftEventListBoxContainer = withWidth()(_GiftEventListBoxContainer);
export {GiftEventListBoxContainer as default};