import React, { useState } from 'react'
import classNames from 'classnames';
import { useSelector } from 'react-redux'
import { IconButton, makeStyles, Tooltip } from '@material-ui/core'
import { walletStrings } from '../../GiftDappWallets';
import { Brightness2, Brightness6, MoreHoriz } from '@material-ui/icons';
import { CustomPopover } from '../../../../includes';
import { GiftDappMedias } from './GiftDappMedias';
import { GIFT_DAPP_LOCAL_STORAGE_VAR, GIFT_DAPP_THEMES } from '../../../../utils';
import useGAEventsTracker from './../../../../hooks/useGAEventsTracker';

const style = makeStyles(theme => ({
    root : {
        display : "flex",
        fontSize : 12,
        [theme.breakpoints.down("_sm")] : {
            position : "fixed",
            border : `solid 1px ${theme.colors.background}`,
            borderRadius : "20px 20px 0 0",
            bottom : 0,
            left : 0,
            zIndex : 200,
            width : "100%",
            display : "flex",
            justifyContent : "center",
            alignItems : "flex-end",
            padding : 10,
            background : theme.colors.background,
            borderRadius : "20px 20px 0 0"
        }
    },
    connecting : {
        padding : "10px 20px",
        background : theme.colors.backgroundAccent,
        color : theme.colors.textSecondary,
        borderRadius : 20,
        fontSize : 12,
    },
    iconBtn : {
        // background : !theme.isDark ? theme.colors.secondaryDark : theme.colors.secondary,
        border : `solid 1px ${theme.colors.homeBorder}`,
        borderRadius : 5,
        padding : 10,
        margin : "0px 2px",
        // "&:hover" : {
        //     background : !theme.isDark ? theme.colors.secondaryDark : theme.colors.background,
        // },
        [theme.breakpoints.up("sm")] : {
            margin : "0 5px"
        }
    },
    iconBtnConnected : {
        // padding : 12,
    },
    icon : {
        color : !theme.isDark ? theme.colors.textSecondary : theme.colors.textBackground,
    },
    extraBtn : {
        display : "flex",
        alignItems : "center",
        // [theme.breakpoints.only("xs")] : {
        //     display : "none",
        // }
    }
}))
function GiftDappConnection({switchTheme}) {
    const classes = style(),
    [state, _setState] = useState({anchorEl : null}),
    {anchorEl} = state,
    setState = (_state, callback) => _setState(state=>({...state, ..._state}), callback),
    {walletReducer} = useSelector(({walletReducer})=>({walletReducer})),
    {
        [walletStrings.address] : address,
    } = walletReducer;
    const themeType = localStorage.getItem(GIFT_DAPP_LOCAL_STORAGE_VAR.THEME_TYPE),
        isDarkMode = themeType === GIFT_DAPP_THEMES.DARK;

    const GAEventsTracker = useGAEventsTracker();

    return (
        <div className={classes.root}>
            <div className={classes.extraBtn}>
                <IconButton className={classNames(classes.iconBtn, address ? classes.iconBtnConnected : "")} 
                    onClick={({target : anchorEl})=> { 
                        setState({anchorEl}); 
                        GAEventsTracker("display_social_menu", "header");
                    }}>
                    <MoreHoriz fontSize="small" className={classes.icon}/>
                </IconButton>
                <Tooltip title={isDarkMode ? "Light mode" : "Night mode"} arrow>
                    <IconButton className={classNames(classes.iconBtn, address ? classes.iconBtnConnected : "")} onClick={() => { switchTheme && switchTheme(); GAEventsTracker("switch_theme", "header"); }}>
                        {isDarkMode ? <Brightness6 fontSize="small" className={classes.icon}/> : <Brightness2 fontSize="small" className={classes.icon}/>}
                    </IconButton>
                </Tooltip>
            </div>
            <CustomPopover
                anchorEl={anchorEl}
                onClose={() => setState({anchorEl : null})}
            >
                <GiftDappMedias/>
            </CustomPopover>
        </div>
    )
}
GiftDappConnection.propTypes = {

}
export default GiftDappConnection;
export {GiftDappConnection};
