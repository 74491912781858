import { Button, makeStyles } from '@material-ui/core'
import GiftDappJumbotron from "./GiftDappJumbotron/GiftDappJumbotron"
import GiftDappPIE from "./GiftDappPIE/GiftDappPIE"
import GiftDappNetworks from "./GiftDappNetworks/GiftDappNetworks"
import GiftDappDefiJourney from "./GiftDappDefiJourney/GiftDappDefiJourney"
import GiftDappBluePrint from "./GiftDappBluePrint/GiftDappBluePrint"
import React, { useCallback, useState } from 'react'
import { giftRewardStrings, REWARD_FIELD_NAMES, walletStrings, WALLET_REDUCER_NAME } from '../../../giftdapp/components'
import { giftRewardActions } from '../../../giftdapp/components'
import { useDispatch, useSelector } from 'react-redux'
import { CustomDialog, warningToast } from '../../../giftdapp/includes'
import { giftContractActions, giftDappRouteNames } from '../../../giftdapp/utils'
import { history } from '../../helpers'
import GiftDappRewardRequestFee from '../../../giftdapp/components/GiftDappReward/GiftDappRewardRequestFee/GiftDappRewardRequestFee'
const style = makeStyles(({colors, breakpoints}) => ({
    root: {
        backgroundColor: colors.pageBackground,
        minHeight: "100vh",
        color: colors.pageColor,
        margin:"0px ",
        paddingTop:100,
        [breakpoints.down('md')]: {
            paddingTop:50
        },
    },
    link : {
        color : colors.linkcolor,

    },
     dialogPaper : {
        background : colors.background,
        border : "solid 1px " + colors.accentSecondary,
        overflow : "visible",
    },
    dialogClose : {
        background : colors.text,
        top : -40,
        padding : 5,
        "&:hover" : {
            background : colors.accent,
            color : colors.textSecondary
        }
    },
}))
export default function LandingHome () {
    const classes = style(),
    dispatch= useDispatch(),
    [state, _setState] = useState({
        openDialog : false,
        dialogType : null, //DIALOG_TYPES.URL,
        isProject : null,
    }),
    {
        isProject, openDialog, dialogType
    } = state,
    setState = (_state, callback) => _setState(state=>({...state, ..._state}), callback),
    DIALOG_TYPES = {
        REQUEST_FEE : "request_fee",
        CONFIRM : "confirm",
        URL : "share_url",
    },
    address = useSelector(state=>state[WALLET_REDUCER_NAME][walletStrings.address]),
    closeDialog = useCallback(() => {
        if(dialogType === DIALOG_TYPES.URL) 
            return history.push(giftDappRouteNames().dashboard);
        else setState({openDialog : false, dialogType : null})
    }, [dialogType]),
    ownerContinue = useCallback(isProject => {
        if(!address) return warningToast("Not connected to a network!")(dispatch);
        //this will replace every other data in the reward space.
        //else get the existing data into it
        giftRewardActions.resetRewardData({
            [giftRewardStrings.rewardTokens] : null,
            [giftRewardStrings.rewardData] : {
                [REWARD_FIELD_NAMES.IS_PROJECT] : isProject
            }
        })(dispatch);
        setState({
            isProject, 
            openDialog : true, 
            dialogType : DIALOG_TYPES.REQUEST_FEE
        });
    }, [address])
    return (
        <div className={classes.root}>
            <GiftDappJumbotron rewardAction={ownerContinue}/>
            <GiftDappPIE />
            <GiftDappNetworks />
            <GiftDappBluePrint />
            <GiftDappDefiJourney />
            <CustomDialog
                within
                open={openDialog}
                close={closeDialog}
                classes={{
                    dialogPaper : classes.dialogPaper,
                    _close : classes.dialogClose
                }}
            >
                <GiftDappRewardRequestFee
                    onClose={closeDialog}
                    onContinue={() => {
                        giftContractActions(isProject).switchContract();
                        history.push(giftDappRouteNames().rewardSetup)
                    }}
                />
            </CustomDialog>
        </div>
    )
}