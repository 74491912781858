import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import { ConnectButton } from '../../../../../includes'
import { WALLET_KEYS } from '..'
import GiftDappMetamaskConnectionProgress from './GiftDappMetamaskConnectionProgress/GiftDappMetamaskConnectionProgress'
import { walletStrings, WALLET_REDUCER_NAME } from '../../../GiftDappWallets'
import { GiftDappBinanceNetwork } from './GiftDappBinanceNetwork'
import GiftDappWalletModal from './../GiftDappWalletModal/GiftDappWalletModal';
import useGAEventsTracker from './../../../../../hooks/useGAEventsTracker';

const style = makeStyles(({colors, isDark}) => ({
    root : {
        display : "flex",
        alignItems : "center",
    },
    rgp : {
        background : !isDark ? colors.backgroundDark : colors.background,
        margin: "0px 5px",
        padding: 13,
        borderRadius : 5,
    },
    list : {
        color : colors.textSecondary,
        "&:hover" : {
            background : colors.backgroundAccent,
        }
    },

    dialogPaper : {
        background : colors.background,
        border : "solid 1px " + colors.accentSecondary,
        color : colors.text,
        overflow : "visible",
    },
    dialogClose : {
        background : colors.text,
        top : -40,
        padding : 5,
        "&:hover" : {
            background : colors.accent,
            color : colors.textSecondary
        }
    },
    btn : {
        borderRadius : 5,
    }
}))
function GiftDappConnect() {
    const classes = style(),
    dispatch = useDispatch(),
    [state, _setState] = useState({
        anchorEl : null, openDialog : false, dialogType : "",
    }),
    {anchorEl, openDialog, dialogType} = state,
    setState = (_state, callback) => _setState(state=>({...state, ..._state}), callback),
    closePopover = ()=>setState({anchorEl : null}),
    listAction = useCallback(networkType => {
        closePopover();
        setState({openDialog : true, dialogType : networkType});
    }, []),
    closeDialog = () => {
        setState({dialogType : "", openDialog : false});
    },
    networkItems = [
        {_id : WALLET_KEYS.BINANCE, name : "Binance Smart Chain Network", avatar : "/assets/bnb.png"}
    ];
    if(process.env.NODE_ENV === "development"){
        networkItems.push({_id : WALLET_KEYS.ETHEREUM, name : "Ethereum Network", avatar : "/assets/eth.png"})
    }
    const [displayWallet, setDisplayWallet] = useState(false);
    const GAEventsTracker = useGAEventsTracker();

    return (
        <div className={classes.root}>
            <ConnectButton
                onClick={()=> { setDisplayWallet((state) => !state); GAEventsTracker("connect_wallet", "header"); }}
            />
            <GiftDappWalletModal displayWallet={displayWallet} setDisplayWallet={setDisplayWallet}/>
            {/* <div className={classes.rgp}>RGP</div> */}
        </div>
    )
}
GiftDappConnect.propTypes = {

}
export default GiftDappConnect;
export {GiftDappConnect};
