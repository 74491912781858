import { makeStyles } from '@material-ui/styles';
import { ButtonBase, Divider, Grid } from '@material-ui/core';
import { landingRouteNames } from '../../../utils'
import createBrowserHistory from 'history/createBrowserHistory';
import React from 'react'
import { giftDappRouteNames } from '../../../../giftdapp/utils';
import useGAEventsTracker from '../../../../giftdapp/hooks/useGAEventsTracker';
import { CheckCircleOutline } from '@material-ui/icons';


const styles = makeStyles(({colors, breakpoints})=>({
    
    root: {
        color: colors.pageColor,
        position: "relative",
        transformStyle: "preserve-3d",
        minHeight: "801px",
        paddingTop: "3rem",
        paddingBottom: "3rem",
        boxSizing: "border-box",
        backgroundColor:colors.pageBackground,
        [breakpoints.down('md')]: {
            paddingRight: "2.5rem",
            paddingLeft: "2.5rem",
        },
        [breakpoints.down('sm')]: {
            paddingRight: "1rem",
            paddingLeft: "1rem",
            minHeight: "501px",
        },
        display : "flex",
        flexDirection : "column",
        justifyContent:"center",
        alignItems : "center",
        borderRadius : 10,
        fontFamily : "revert",
        [breakpoints.up("sm")] : {
            padding : 20
            // padding : "20px 50px"
        },
        // maxWidth : 1000,
        [breakpoints.up("lg")] : {

        }
    },
    jumboHeading: {
        fontSize: "56px",
        fontFamily: "Clash Display",
        fontStyle: "normal",
        fontWeight: 600,

        color: colors.pageColor,
        marginBottom: "1rem",
        marginTop: 30,
        [breakpoints.down('lg')]: {
            fontSize: "56px",
        },
        [breakpoints.down('sm')]: {
            fontSize: "32px",
        }
    },
    jumboText: {
        fontSize: "18px",
        fontFamily: "DM Sans",
        fontStyle: "normal",
        fontWeight: "normal",
        textAlign:"center",
        color: colors.pageColor,
        lineHeight: "28px",
        opacity: .8,
        marginBottom: "4rem",
        maxWidth: "53%",
        margin: "0 auto 1.2rem",
        [breakpoints.down('xl')]: {
            fontSize: "20px",
        },
        [breakpoints.down('sm')]: {
            fontSize: "16px",
            maxWidth: "100%",
        }
    },
    jumboBtn: {
        backgroundImage: " linear-gradient(90deg, #2691C1 0%, #045CA6 42.71%, #055DA6 60.42%, #238CBE 100%)",
        color: colors.textDefault,
        fontSize: "18px",
        width: "196px",
        height: "56px",
        fonWeight: "700",
        marginBottom:60,
        padding: "1.5rem 4rem",
        borderRadius: "6px",
        outline: "none",
        [breakpoints.down('xl')]: {
            fontSize: "18px",
            padding: ".7rem 1.5rem",
            borderRadius: ".5rem",
        }
    },
    box : {
        position : "relative",
        fontSize : "smaller",
        border : `dashed 0.5px ${colors.homeBorder}`,
        color : colors.homeText,
        cursor:"pointer",
        marginTop:30,
        fontSize : 14,
        borderRadius : 10,
        [breakpoints.up("sm")] : {
        },
        "&:hover": {
        transform:"translate(0px, -15px)",
        transition:"0.3s",
        border : `dashed 0.5px ${colors.buttonColor}`,
        }
    },
    box2 : {
        "&:hover": {
        transform:"rotate(3deg)",
        transition:"0.3s"
        }
    },
    box3 : {
        "&:hover": {
        transform:"rotate(-3deg)",
        transition:"0.3s"
        }
    },
    imgContainer:{
        paddingTop: "40px",
        [breakpoints.down('md')]: {
            maxWidth: "100%",
        },
        [breakpoints.down('sm')]: {
            maxWidth: "100%",
        }
    },
    image:{
        [breakpoints.down('md')]: {
            maxWidth: "100%",
        },
        [breakpoints.down('sm')]: {
            maxWidth: "100%",
        }
    },
    funColor:{
        color: colors.funColor
    },
    breaker : {
        marginTop : 50,
        [breakpoints.down("xs")] : {
            marginTop : 50,
        }
    },
    description : {
        display : "flex",
        fontSize : 18,
        flexDirection : "column",
        textAlign : "center",
        alignItems : "center",
        justifyContent : "center",
        lineHeight : 2,
        color : colors.text, // new design color "#DCE5EF",
        // padding : "20px 50px",
        [breakpoints.up("sm")] : {
            padding : "20px 50px",
        },
        [breakpoints.up("md")] : {
            padding : "20px 100px",
        },
        [breakpoints.up("lg")] : {
            padding : "20px 120px",
        },
        [breakpoints.down("xs")] : {
            justifyContent : "flex-start",
            textAlign : "start"
        },
    },
    itemBody : {
        minHeight : 380,
        padding : 20,
        [breakpoints.down("sm")] : {
            minHeight : 400,

        }
    },
    divider : {
        background : colors.homeBorder,
        marginTop : 20,
        borderTop : `solid .1 gray !important`,
        // boxShadow: "0px 0px 10px"
    },
    _header : {
        fontSize : 20,
        fontWeight : "bold",
        marginBottom : 20,
        fontFamily:"Clash Display",
        [breakpoints.down("sm")] : {
           fontSize:16
        }
    },
    homeText : {

    },
    imageContainer : {
        padding : 20,
        display : "flex",
        justifyContent : "center",
        alignItems : "center",
        height : 200,
    },
    image : {
        height : 200,
        width : 250,
    },
    icon : {
        color : colors.homeLiner,
        minWidth : 40,
        [breakpoints.down("sm")] : {
            // display : "none",
        }
    },
    info : {
        lineHeight : 2,
        marginBottom : 10,
    },
    _itemContainer : {
        display : "flex",
        alignItems : "center",
        marginBottom : 15,
    },
    btnContainer : {
        position : "absolute",
        bottom : 0,
        left : 0,
        // width : "calc(100% - 40px)",
        width : "100%",
        padding :20,
    },
    button : {
        border : `solid 1px ${colors.buttonColor}`,
        background:colors.landItmeBg,
        boxShadow: "0px 0.888672px 6.2207px -1.77734px rgba(24, 39, 75, 0.06), 0px 1.77734px 1.77734px rgba(24, 39, 75, 0.06)",
        borderRadius: 6,
        color:colors.buttonColor,
        padding : 10,
        display : "flex",
        justifyContent : "center",
        textAlign : "center",
        borderRadius : 5,
        cursor : "pointer",
        "&:hover" : {
            background:colors.buttonColor,
            color:colors.textBackground,
            transition:0.3
        }
    },
    gridContainer:{
        [breakpoints.up("md")] : {
            width:"83%",
            margin: "0 auto"
        },
    }
    
}))


export default function GiftDappJumbotron({rewardAction}) {
    
    const classes = styles(),
    GAEventsTracker = useGAEventsTracker(),
   history = createBrowserHistory({forceRefresh:true});

   const pageData = [
        {
            header : "For Influencer",
            description : <div>Become a reputable influencer with the opportunity to gift crypto to your followers and subscribers.</div>,
            items : [
                "Reward your followers and supporters",
                "Become more reputable",
                "Generate more clicks and popularity"
            ],
            image : "/assets/home/influencer.png",
            clickAction : () => rewardAction(false)
        },{
            header : "For Projects",
            description : <div>
                Reward your users in a funny way while also saving time and money. </div>,
            items : [
                "Increase hype, support and userbase",
                "Land major investors by becoming reputable",
                "Generate more clicks and popularity"
            ],
            image : "/assets/home/project.png",
            clickAction : () => rewardAction(true)
        },{
            header : "For Everyone Else",
            description : <div>
                Still curious about what you can accomplish? We let you do it all:
            </div>,
            items : [
                "Claim instant rewards from your favourite influencers and projects.",
                "Refer your friends for more rewards",
            ],
            image : "/assets/home/everyone.png",
            clickAction : () => history.push(giftDappRouteNames().events)
        }
    ]
    return(
        <header className = {classes.root}>
                <h1 className = {classes.jumboHeading}>
                Gift your users in <span className={classes.funColor}>fun</span> ways
                </h1>
                <p className = {classes.jumboText}>
                Reward your users or followers in a fun way by having them win tokens through games
                </p>
                <ButtonBase 
                    onClick={() => window.location.href = landingRouteNames().app}
                    className = {classes.jumboBtn}>
                        Launch DApp
                </ButtonBase>
                <Grid container spacing={4} className={classes.gridContainer}>
            {pageData.map((item, index) => {
                const {header, description, items, image, clickAction} = item;
                return <Grid key={index} item xs={12} sm={6} md={4} lg={4} xl={4} >
                    <div className={ index ===1 ?`${classes.box}` : index ===2 ? `${classes.box} ${classes.box2}` : `${classes.box} ${classes.box3}` } >
                        <p className={classes.imageContainer}>
                            <img className={classes.image} src={image} title={header}/>
                        </p>
                        <Divider className={classes.divider}/>
                        <div className={classes.itemBody}>
                            <h1 className={classes._header}>
                                {header}
                            </h1>
                            <div className={classes.info}>
                                {description}
                            </div>
                            <div className={classes.itemContainer}>
                                {items.map((_item, index) =>{
                                    return <div key={index} className={classes._itemContainer}>
                                        <CheckCircleOutline className={classes.icon}/>
                                        <div className={classes._item}>{_item}</div>
                                    </div>
                                })}
                            </div>
                            <div className={classes.btnContainer}>
                                <div className={classes.button} onClick={() => { clickAction(); GAEventsTracker(`get_started_${header}`, "reward page"); }}>Get Started</div>
                            </div>
                        </div>
                    </div>
                </Grid>

            })}
            
        </Grid>
            </header>
    );
}