import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Hidden, makeStyles, withWidth } from '@material-ui/core'
import { ArrowDropDown } from '@material-ui/icons'
import classNames from 'classnames';
import { walletStrings, WALLET_REDUCER_NAME } from '../../../GiftDappWallets'
import walletUtil from '../../../../../utils/web3/walletUtil'
import { getContractInstance, giftContractActions, shortAddress, tokenContractActions,NETWORKS } from '../../../../../utils'
import { CustomDialog, errorToast } from '../../../../../includes'
import { GiftDappNetworks } from '../GiftDappNetworks';
import { WALLET_KEYS } from '..'
import walletConnection from '../../../../../utils/web3/walletConnection';
import GiftDappWalletSwitchModal from './../GiftDappWalletSwitchModal/GiftDappWalletSwitchModal';
import useGAEventsTracker from './../../../../../hooks/useGAEventsTracker';
import GiftDappConnect from '../GiftDappConnect/GiftDappConnect';
import GiftDappConnection from '../GiftDappConnection';
//
const style = makeStyles(({breakpoints, isDark, colors : {
    secondaryDark, secondary, secondary2, homeBorder, backgroundDark,
    background, textBackground,text, accentSecondary, accent, homeLiner,
    textSecondary
}}) => ({
    root : {
        display : 'flex',
        alignItems : "center",
        [breakpoints.down("xs")] : {
            // flexDirection : "column",
            fontSize : 10,
            // flexDirection : "row-reverse"
        },
        [breakpoints.down("md")] : {
            position : "fixed",
            border : `solid 1px ${background}`,
            borderRadius : "20px 20px 0 0",
            bottom : 0,
            left : 0,
            zIndex : 200,
            width : "100%",
            display : "flex",
            // justifyContent : "center",
            // alignItems : "flex-end",
            padding : 10,
            background : background,
            borderRadius : "20px 20px 0 0"
        }
    },
    balance : {
        display : "flex",
        background : !isDark ? "" : secondary,
        border: `1px solid ${homeBorder}`,
        color: !isDark ? text : homeLiner,
        padding: 12,
        alignItems : "center",
        borderRadius : 5,
        margin : "0px 5px",        
        [breakpoints.down("sm")] : {
            display : "none",
        }
    },
    midiumlogo: {
        height: 18,
        width: 18,
        marginRight:5
      },
    amount_add_holder : {
        padding : 5,
        display : "flex",
        margin : "0px 2px",
        borderRadius : 5,
        alignItems : "center",
        background : !isDark ? "" : secondary,
        border: `1px solid ${homeBorder}`,
        color: text,
    },
    eth : {
        margin : "0 10px",
    },
    address : {
        display : "flex",
        alignItems : "center",
        padding : 10,
        background : !isDark ? "" : secondary2,
        borderRadius : 0,
        cursor : "pointer",
    },
    metamask_img : {
        height : 13,
        width : 13,
        [breakpoints.up("sm")] : {
            marginLeft : 5
        }
    },
    iconBtn : {
        background : !isDark ? "" : secondary,
        borderRadius : 5,
        padding : 10,
        margin : "0px 2px",
        "&:hover" : {
            background : !isDark ? secondaryDark : background,
        },
        [breakpoints.up("sm")] : {
            margin : "0 5px"
        }
    },
    iconBtnConnected : {
        padding : 12,
    },
    icon : {
        color : textBackground
    },
    cursor : {
        cursor:"pointer"
    },
    dialogPaper : {
        background : background,
        border : "solid 1px " + accentSecondary,
        color : text,
        overflow : "visible",
    },
    dialogClose : {
        background : text,
        top : -40,
        padding : 5,
        "&:hover" : {
            background : accent,
            color : textSecondary
        }
    },
    iconDd : {
        color : homeLiner
    }
}))
function _GiftDappConnected({width, switchTheme, ...props}) {
    const classes = style(),
        [state, _setState] = useState({
            mainBalance : 0,
            rigelBalance : 0,
            openDialog : false,
        }),
        { mainBalance, rigelBalance, openDialog } = state,

        setState = _state => _setState(state=>({...state, ..._state})),
    dispatch = useDispatch(),
    closeDialog = () => {
        setState({...state,dialogType : "", openDialog : false});
    },
    walletReducer = useSelector(state=>state[WALLET_REDUCER_NAME]),
    {
        [walletStrings.address] : address,
        [walletStrings.networkType] : networkType,
        [walletStrings.networkWallet] : networkWallet,
        [walletStrings.web3] : web3,
        [walletStrings.contractAddresses] : contractAddresses,
        [walletStrings.networkId] : networkId
    } = walletReducer,
    onNetworkSelect = useCallback(async(networkId)=>{
        if(!address) return;
        try{
            const _id = walletUtil.getTruffleNetworkName(networkId);
            const provider = web3._provider;
            if(!NETWORKS[_id]) throw new Error("Network metadata not configured");
            else{
                await walletUtil.switchNetwork(provider, NETWORKS[_id].chainId, NETWORKS[_id]);
                await walletConnection(
                    contractAddresses, 
                    networkWallet, 
                    null, true
                );
            }
        }catch(e){
            console.error(e)
        }
    },[]),
    rgpTokenAmount = amount => {
        if(isNaN(amount)) amount = 0;
        const tenseFigure = getTenseLiteral(Number(amount), 1000);
        let unit = "";
        switch( tenseFigure ) {
            case 1 : unit = "K"; break;
            case 2 : unit = "M"; break;
            case 3 : unit = "B"; break;
            case 4 : unit = "T"; break;
        }
        return Math.round(amount / 10 ** (tenseFigure * 3)) + unit;
        // return String(amount).length > 6 ? String(Number(amount).toLocaleString()).substr(0, 6) + "..." : Number(amount).toLocaleString()
    }, 
    getTenseLiteral = (amount, divisor) => {
        let result = 0;
        if(amount < divisor) result = 0;
        else {
            result++;
            result += getTenseLiteral(amount, divisor * 1000);
        }
        return result;
    };
    useEffect(()=>{
        (async() => {
            try{
                const [mainBalance, rigelBalance] =
                    await Promise.all([
                        walletUtil.getBalance(),
                        tokenContractActions(
                            await getContractInstance(
                                web3, null,
                                await giftContractActions().RGPTokenAddress()
                            )
                        ).myBalance()
                    ])
                    setState({mainBalance, rigelBalance})
            }catch({message}){
                errorToast(message)(dispatch);
            }
        })();
    }, [address])
    const _symbol = walletUtil.networkTokens[networkType] || " ETH";
    const [displayModal, setDisplayModal] = useState(false);
    const GAEventsTracker = useGAEventsTracker(),
    networkInfo = walletUtil.networkList.find(item => item.networkId === networkId),
    logo = networkInfo?.image || "/assets/bnb.png",
    networkName = walletUtil.getTruffleNetworkName(networkInfo?.networkId) || "Dev",
    walletImage = walletUtil.walletItems.find(i => i.id === networkWallet)?.image;
    return (
        <div className={classes.root}>
            <div className={classNames(classes.balance, classes.cursor)} title={networkName} onClick={()=> { setState({...state,dialogType : "", openDialog : true}); GAEventsTracker("display_network_modal", "header"); }}>
                {logo && <img src={logo} className={classes.midiumlogo} alt={"Binance"}/>}  
                {networkName}
                <ArrowDropDown className={classes.iconDd}/>
            </div>
            <div style={{padding : 15}} className={classes.balance} title={`${Number(rigelBalance).toLocaleString()} RGP`}>
                {rgpTokenAmount(rigelBalance)} RGP
            </div>
            <div className={classes.amount_add_holder}>
                <div className={classes.eth}>
                    {Number(mainBalance).toLocaleString()}
                    {' ' + _symbol}
                </div>
                <div className={classes.address} onClick={()=> { setDisplayModal((state) => !state); GAEventsTracker("display_switch_modal", "header"); }}>
                    {width !== "xs" && shortAddress(address)}
                    <img src={walletImage} className={classes.metamask_img}/>
                    {/* <img src={`/assets/${networkWallet === WALLET_KEYS.TRUSTWALLET ? "bnb" : "metamask"}.png`} className={classes.metamask_img}/> */}
                </div>
            </div>
            <div style={{display : "flex", flexGrow : 1}}/>
            <Hidden mdUp>
                <GiftDappConnection switchTheme={switchTheme}/>
            </Hidden>
            <GiftDappWalletSwitchModal
              displayModal={displayModal}
              setDisplayModal={setDisplayModal}
            />
            <CustomDialog
                within
                classes={{
                    dialogPaper : classes.dialogPaper,
                    _close : classes.dialogClose,
                }}
                open={openDialog}
                close={closeDialog}
            >
               <GiftDappNetworks
               network={networkType}
                onSelect={onNetworkSelect}
               />
            </CustomDialog>
        </div>
    )
}
_GiftDappConnected.propTypes = {

}
export const GiftDappConnected = withWidth()(_GiftDappConnected);
export {GiftDappConnected as default};
